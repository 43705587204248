<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class=""
      right
      no-header
      backdrop
      shadow
      width="780px"
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <div>
          <label class="mb-0">
            <h6 class="mb-0">
              Decimal Places
              <feather-icon
                class="cursor-pointer"
                icon="InfoIcon"
                size="16"
                v-b-tooltip.hover.bottom
                title="This will be use in salary allowances & deductions"
              />
            </h6>
          </label>
          <b-badge
            v-for="item in decimalData"
            :key="item"
            :variant="
              $store.state.decimal == item ? 'primary' : 'light-primary'
            "
            class="ml-50 cursor-pointer"
            @click="$store.commit('setDecimal', item)"
            >{{ item }}</b-badge
          >
          <feather-icon
            class="ml-2 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hideSideBar()"
          />
        </div>
      </div>

      <div class="p-1" v-if="!showSettings && !showDetails">
        <b-row>
          <b-col md="3">
            <b-form-group
              label="Salary Month"
              invalid-feedback="Salary Month is required."
              ref="month"
            >
              <v-select
                v-model="sal.month"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="months"
                label="text"
                :reduce="(month) => month.value"
                :clearable="false"
                ref="month"
                placeholder="Select a month"
                @input="LoadSalary()"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              label="Year"
              invalid-feedback="Year is required."
              ref="year"
            >
              <v-select
                v-model="sal.year"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                :options="years"
                :clearable="false"
                ref="year"
                placeholder="Select salary year"
                @input="LoadSalary()"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Payment Date"
              invalid-feedback="Payment date is required."
              ref="date"
            >
              <flat-pickr
                ref="date"
                :config="config"
                v-model="currentDate"
                class="form-control"
                placeholder="Select Date."
                @on-change="saveDate()"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Department"
              invalid-feedback="Department is required."
            >
              <v-select
                v-model="mSalary.salary.departmentID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="departments"
                :reduce="(opt) => opt.id"
                label="name"
                :clearable="false"
                placeholder="Select department"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-card class="mt-1" v-if="false">
          <div class="d-flex justify-content-between">
            <h4 class="">Custom Payroll Policy</h4>
            <b-form-checkbox v-model="customObj.show" switch />
          </div>

          <hr />
          <b-row
            v-if="customObj.show"
            v-for="(item, index) in policyObj"
            :key="index"
            class="align-items-center"
          >
            <b-col md="3" class="pr-0">
              <b-form-group
                label="Leaves By"
                invalid-feedback="Leaves By is required."
                ref="leave_type"
              >
                <v-select
                  v-model="item.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="leaveTypes"
                  :reduce="(op) => op.value"
                  :clearable="false"
                  label="text"
                />

                <!-- @input="changeAllow()" -->
              </b-form-group>
            </b-col>
            <b-col md="4" class="pr-0" v-if="item.type == 'monthly'">
              <b-form-group
                label="Month"
                invalid-feedback="Month is required."
                ref="pmonth"
              >
                <v-select
                  v-model="item.month"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="months"
                  :clearable="false"
                  ref="pmonth"
                  placeholder="Select"
                />
              </b-form-group>
            </b-col>
            <b-col md="3" class="pr-0" v-if="item.type == 'yearly'">
              <b-form-group
                label="From"
                invalid-feedback="From Date is required."
                ref="dtFrom"
              >
                <flat-pickr
                  ref="dtFrom"
                  :config="config"
                  v-model="item.dateFrom"
                  class="form-control"
                  placeholder="Select Date."
                  @on-change="setTillDate(item)"
                />
              </b-form-group>
            </b-col>
            <b-col md="3" class="pr-0" v-if="item.type == 'yearly'">
              <b-form-group
                label="Till"
                invalid-feedback="Till Date is required."
                ref="dtTo"
              >
                <flat-pickr
                  disabled
                  ref="dtFrom"
                  :config="config"
                  v-model="item.dateTo"
                  class="form-control"
                  placeholder=""
                />
              </b-form-group>
            </b-col>
            <!--
            <b-col md="5" class="pr-0" v-if="item.type == 'yearly'">
              <b-form-group
                label="Date"
                invalid-feedback="Date is required."
                ref="dtFrom"
              >
                <flat-pickr
                  ref="dtFrom"
                  :config="config2"
                  v-model="item.range"
                  class="form-control"
                  placeholder="Select Date."
                />
              </b-form-group>
            </b-col>
            -->
            <b-col :md="item.type == 'monthly' ? '3' : '2'" class="pr-0">
              <b-form-group
                label="Leaves"
                invalid-feedback="Leaves is required."
                ref="leave_days"
              >
                <b-form-input
                  ref="leave_days"
                  type="number"
                  placeholder="Enter days"
                  v-model="item.days"
                />
              </b-form-group>
            </b-col>
            <b-col md="1" class="pr-0 pl-50">
              <b-button
                v-if="index == policyObj.length - 1"
                variant="primary"
                class="btn-icon rounded-circle"
                @click="AddPolicy(item)"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
              <b-button
                v-else
                variant="outline-danger"
                class="btn-icon rounded-circle"
                @click="removePolicy(item, index)"
              >
                <feather-icon size="16" icon="Trash2Icon" />
              </b-button>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="mt-1">
          <div class="d-flex justify-content-between">
            <h4 class="">
              Attendance
              <feather-icon
                icon="RefreshCwIcon"
                size="16"
                class="ml-50 cursor-pointer"
                v-b-tooltip.hover.top
                title="Refresh"
                @click="LoadSalary()"
              />
            </h4>
            <b-form-checkbox
              v-if="sunObj && sunmonDepID != 9"
              v-model="sunmonValue"
              switch
              v-b-tooltip.hover.top
              title="Friday / Monday Policy"
            />
          </div>
          <b-row class="mt-1">
            <b-col md="4">
              <b-form-group
                label="Working Days"
                invalid-feedback="Working Days is required."
                ref="wDays"
              >
                <b-form-input
                  placeholder="Enter working days"
                  ref="wDays"
                  type="number"
                  v-model="mSalary.salary.workingDays"
                  @input="setP()"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Present"
                invalid-feedback="Invalid value entered"
                ref="present"
              >
                <b-form-input
                  placeholder="Present no. of days"
                  ref="present"
                  type="number"
                  v-model="mSalary.salary.present"
                  @input="setAbsent()"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                invalid-feedback="Invalid value entered"
                ref="absent"
              >
                <label class="bv-no-focus-ring col-form-label pt-0"
                  >Absent (<strong
                    >{{
                      mSalary.salary.absentDeductionCount
                    }}
                    deductible</strong
                  >)</label
                >
                <b-form-input
                  placeholder="Absent no. of days"
                  ref="absent"
                  type="number"
                  v-model="mSalary.salary.absent"
                  @input="setP()"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                invalid-feedback="Invalid value entered"
                ref="leave"
              >
                <div class="d-flex justify-content-between">
                  <label class="bv-no-focus-ring col-form-label pt-0"
                    >Leave (<strong
                      >{{ mSalary.remainingLeaves }} remaining</strong
                    >)</label
                  >
                  <feather-icon
                    class="cursor-pointer"
                    icon="InfoIcon"
                    size="18"
                    v-b-tooltip.html.hover.top
                    :title="
                      `<b>Max leaves per month</b><hr style='margin: 0.15rem 0'/><p class='text-left m-0'>` +
                      mSalary.maxLeavePerMonth +
                      ' Allowed</p>'
                    "
                  />
                </div>

                <b-form-input
                  placeholder="Leave no. of days"
                  ref="leave"
                  type="number"
                  v-model="mSalary.salary.leave"
                  @input="setP()"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group invalid-feedback="Invalid value entered" ref="late">
                <label class="bv-no-focus-ring col-form-label pt-0"
                  >Late Coming (<strong
                    >{{ mSalary.salary.lateDeductionCount }} deductible</strong
                  >)</label
                >
                <b-form-input
                  placeholder="Late Coming no. of days"
                  ref="late"
                  type="number"
                  v-model="mSalary.salary.late"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group invalid-feedback="Invalid value entered" ref="half">
                <label class="bv-no-focus-ring col-form-label pt-0"
                  >Half Leave (<strong
                    >{{
                      mSalary.salary.shortLeaveDeductionCount
                    }}
                    deductible</strong
                  >)</label
                >
                <b-form-input
                  placeholder="Half Leave no. of days"
                  ref="half"
                  type="number"
                  v-model="mSalary.salary.shortLeave"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Bonus Working Days"
                invalid-feedback="Bonus Working Days is required."
                ref="bDays"
              >
                <b-form-input
                  placeholder="Enter bonus working days"
                  ref="bDays"
                  type="number"
                  v-model="mSalary.salary.bonusDays"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Leave Encashment Days"
                invalid-feedback="Leave Encashment Days is required."
                ref="enDays"
              >
                <b-form-input
                  placeholder="Enter leave encashment days"
                  ref="enDays"
                  type="number"
                  v-model="mSalary.salary.leavesEncashed"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                @click="CalculateSalary()"
                :disabled="requestCalculate"
                block
              >
                <b-spinner v-if="requestCalculate" small type="grow" />
                <span v-else> Calculate Salary </span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>

        <b-card class="mt-1">
          <!-- <div class="d-flex justify-content-between">
            <h4 class="">Salary Info</h4>
            <feather-icon
              class="cursor-pointer"
              icon="PlusCircleIcon"
              size="20"
              @click="openDetails()"
            />
          </div> -->

          <b-row class="">
            <b-col md="12" class="mb-50">
              <div class="d-flex justify-content-between">
                <h4 class="">Allowances</h4>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="openReturns()"
                  :disabled="returnLoading"
                >
                  Return Deposits
                </b-button>
              </div>
              <hr />
              <b-row class="">
                <div
                  v-for="(item, index) in mSalary.allowances"
                  :key="index"
                  style="display: flex; align-items: center; width: 100%"
                >
                  <b-col md="7" class="pr-0">
                    <b-form-group
                      label="Allowance"
                      invalid-feedback="Allowance is required."
                      ref="details"
                    >
                      <v-select
                        v-model="item.staffAllowanceID"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="allowances"
                        :reduce="(op) => op.id"
                        :selectable="(opt) => !allowIDs.includes(opt.id)"
                        :clearable="false"
                        label="title"
                        @input="changeAllow()"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="3" class="pr-0">
                    <b-form-group
                      label="Amount"
                      invalid-feedback="Amount is required."
                      ref="sal_amount"
                    >
                      <b-form-input
                        ref="sal_amount"
                        type="number"
                        placeholder="Enter amount"
                        v-model="item.amount"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="2">
                    <b-button
                      v-if="mSalary.allowances.length > 1"
                      @click="removeDetail(item, index)"
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon size="16" icon="Trash2Icon" />
                    </b-button>
                    <b-button
                      v-if="index == mSalary.allowances.length - 1"
                      @click="AddDetail(item)"
                      variant="primary"
                      class="btn-icon rounded-circle ml-50"
                    >
                      <feather-icon size="16" icon="PlusIcon" />
                    </b-button>
                  </b-col>
                </div>
              </b-row>
            </b-col>

            <b-col md="12" class="mb-50">
              <h4 class="">Salary Deductions</h4>
              <hr />
              <b-row class="">
                <div
                  v-for="(item, ind) in mSalary.deductions"
                  :key="ind"
                  style="display: flex; align-items: center; width: 100%"
                >
                  <b-col md="7" class="pr-0">
                    <b-form-group
                      label="Deduction"
                      invalid-feedback="detail is required."
                      ref="ded_details"
                    >
                      <v-select
                        v-model="item.staffDeductionID"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="deductions"
                        :reduce="(op) => op.id"
                        :selectable="(opt) => !dedIDs.includes(opt.id)"
                        :clearable="false"
                        label="title"
                        @input="changeDed()"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="3" class="pr-0">
                    <b-form-group
                      label="Amount"
                      invalid-feedback="Amount is required."
                      ref="ded_amount"
                    >
                      <b-form-input
                        ref="ded_amount"
                        type="number"
                        placeholder="Enter amount"
                        v-model="item.amount"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="2">
                    <b-button
                      v-if="mSalary.deductions.length > 1"
                      @click="removeDeduct(item, ind)"
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon size="16" icon="Trash2Icon" />
                    </b-button>

                    <b-button
                      v-if="ind == mSalary.deductions.length - 1"
                      @click="AddDeduct(item)"
                      variant="primary"
                      class="btn-icon rounded-circle ml-50"
                    >
                      <feather-icon size="16" icon="PlusIcon" />
                    </b-button>
                  </b-col>
                </div>
              </b-row>
            </b-col>

            <b-col md="12">
              <div class="d-flex align-items-center justify-content-between">
                <h4 class="mb-0">Summary</h4>
                <div class="d-flex align-items-center" v-if="false">
                  <h4 class="bv-no-focus-ring col-form-label mr-1">
                    Add to Expense:
                  </h4>
                  <b-form-checkbox v-model="toExpense" switch />
                </div>
              </div>
              <hr />
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Basic Salary"
                invalid-feedback="Basic Salary is required."
                ref="salary"
              >
                <b-form-input
                  placeholder="Basic Salary"
                  ref="salary"
                  disabled
                  v-model="mSalary.salary.basicSalary"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Total Allowance"
                invalid-feedback="Total Allowance is required."
                ref="total_allow"
              >
                <b-form-input
                  placeholder="Total Allowance"
                  ref="total_allow"
                  disabled
                  v-model="mSalary.salary.totalAllownaces"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Gross Salary"
                invalid-feedback="Gross Salary is required."
                ref="gross"
              >
                <b-form-input
                  placeholder="Gross Salary"
                  ref="gross"
                  disabled
                  v-model="mSalary.salary.grossSalary"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Total Deduction"
                invalid-feedback="Total Deduction is required."
                ref="total_deduct"
              >
                <b-form-input
                  placeholder="Total Deduction"
                  ref="total_deduct"
                  disabled
                  v-model="mSalary.salary.totalDedcutions"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                label="Net Salary"
                invalid-feedback="Net Salary is required."
                ref="net"
              >
                <b-form-input
                  placeholder="Net Salary"
                  ref="net"
                  disabled
                  v-model="mSalary.salary.netSalary"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <!-- label="Salary From" -->
              <b-form-group invalid-feedback="Account is required." ref="acc">
                <div class="d-flex justify-content-between">
                  <label class="bv-no-focus-ring col-form-label pt-0"
                    >Salary From</label
                  >
                  <feather-icon
                    class="cursor-pointer"
                    icon="BriefcaseIcon"
                    size="20"
                    v-b-tooltip.hover.top
                    title="Accounts"
                    @click="openAccount()"
                  />
                </div>
                <v-select
                  v-model="mSalary.salary.accountID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="accTypes"
                  :reduce="(opt) => opt.id"
                  label="account"
                  :clearable="false"
                  ref="acc"
                  placeholder="Select account type"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-form-group
          label="Note"
          invalid-feedback="Note is required"
          ref="note"
        >
          <b-form-textarea
            ref="note"
            placeholder="Enter note"
            v-model="mSalary.salary.note"
            rows="2"
          ></b-form-textarea>
        </b-form-group>

        <b-row class="mb-5">
          <b-col cols="4" class="mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="SaveSalary(false)"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else> Save </span>
            </b-button>
          </b-col>
          <b-col cols="4" class="mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="SaveSalary(true)"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>
                <feather-icon class="mr-50" icon="PrinterIcon" /> Save & Print
              </span>
            </b-button>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Add to Expense"
              invalid-feedback="Expense is required."
            >
              <v-select
                v-model="toExpense"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="expOptions"
                :reduce="(opt) => opt.value"
                label="text"
                :clearable="false"
                placeholder="Select"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <div v-if="showDetails" class="p-1">
        <b-button
          @click="showDetails = false"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          class="mb-1"
        >
          <feather-icon icon="ChevronLeftIcon" class="mr-50" />
          <span class="align-middle">Back</span>
        </b-button>
        <b-card class="">
          <h4 class="">Allowances</h4>
          <hr />
          <b-row class="">
            <div
              v-for="(item, index) in AddingInfo"
              :key="index"
              style="display: flex; align-items: center; width: 100%"
            >
              <b-col md="7" class="pr-0">
                <b-form-group
                  label="Allowance"
                  invalid-feedback="Allowance is required."
                  ref="details"
                >
                  <v-select
                    v-model="item.staffAllowanceID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="allowances"
                    :reduce="(op) => op.id"
                    :selectable="(opt) => !allowIDs.includes(opt.id)"
                    :clearable="false"
                    label="title"
                    @input="changeAllow()"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" class="pr-0">
                <b-form-group
                  label="Amount"
                  invalid-feedback="Amount is required."
                  ref="sal_amount"
                >
                  <b-form-input
                    ref="sal_amount"
                    type="number"
                    placeholder="Enter amount"
                    v-model="item.amount"
                  />
                </b-form-group>
              </b-col>

              <b-col md="2">
                <b-button
                  v-if="index == AddingInfo.length - 1"
                  @click="AddDetail(item)"
                  variant="primary"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon size="16" icon="PlusIcon" />
                </b-button>
                <b-button
                  v-else
                  @click="removeDetail(item, index)"
                  variant="outline-danger"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon size="16" icon="Trash2Icon" />
                </b-button>
              </b-col>
            </div>
          </b-row>
        </b-card>
        <b-card class="mb-50">
          <h4 class="">Salary Deductions</h4>
          <hr />
          <b-row class="">
            <div
              v-for="(item, ind) in mSalary.deductions"
              :key="ind"
              style="display: flex; align-items: center; width: 100%"
            >
              <b-col md="7" class="pr-0">
                <b-form-group
                  label="Deduction"
                  invalid-feedback="detail is required."
                  ref="ded_details"
                >
                  <v-select
                    v-model="item.staffDeductionID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="deductions"
                    :reduce="(op) => op.id"
                    :selectable="(opt) => !dedIDs.includes(opt.id)"
                    :clearable="false"
                    label="title"
                    @input="changeDed()"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" class="pr-0">
                <b-form-group
                  label="Amount"
                  invalid-feedback="Amount is required."
                  ref="ded_amount"
                >
                  <b-form-input
                    ref="ded_amount"
                    type="number"
                    placeholder="Enter amount"
                    v-model="item.amount"
                  />
                </b-form-group>
              </b-col>

              <b-col md="2">
                <b-button
                  v-if="ind == mSalary.deductions - 1"
                  @click="AddDeduct(item)"
                  variant="primary"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon size="16" icon="PlusIcon" />
                </b-button>
                <b-button
                  v-else
                  @click="removeDeduct(item, ind)"
                  variant="outline-danger"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon size="16" icon="Trash2Icon" />
                </b-button>
              </b-col>
            </div>
          </b-row>
        </b-card>
      </div>

      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-Fee"
      bg-variant="white"
      v-model="accountbar"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Collection Account</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="accountbar = false"
        />
      </div>

      <b-form class="p-2" @submit.prevent>
        <!-- <b-row class="mt-1" v-if="!addingAcc">
            <b-col md="12">
              <b-button variant="primary" @click="addingAcc = true">
                Add Account
              </b-button>
            </b-col>
          </b-row> -->
        <b-row class="d-flex align-items-center">
          <b-col md="12">
            <b-form-group
              label="Account Name"
              invalid-feedback="Account name is required."
            >
              <b-form-input
                ref="accname"
                v-model="accountObj.account"
                placeholder="Enter collection account name"
                @focusout="checkAccount()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <!--  v-if="entryObj && entryObj.valueBool" -->
          <b-col md="12">
            <b-form-group
              label="Link with"
              invalid-feedback="Account is required."
            >
              <v-select
                ref="coa"
                v-model="accountObj.accountID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="coAccounts"
                :reduce="(opt) => opt.id"
                :clearable="true"
                label="title"
                placeholder="Select account"
                @input="setCOA"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-button
              variant="success"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="saveAcc()"
              :disabled="savingacc"
              block
            >
              <b-spinner v-if="savingacc" small type="grow" />
              <!-- <feather-icon v-else :icon="accIcon" /> -->
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-2"
          show-empty
          :items="accTypes"
          :fields="accFields"
          responsive
          hover
        >
          <template #head(actions)="data">
            <div class="text-right">
              <span>{{ data.label }}</span>
            </div>
          </template>

          <template #cell(actions)="data">
            <div class="text-right">
              <b-button
                variant="primary"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="editAcc(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                variant="outline-danger"
                class="btn-icon mr-0 ml-sm-1 mb-1 mb-sm-0"
                @click="deleteAcc(data.item)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="showSettings"
      sidebar-class=""
      right
      no-header
      backdrop
      shadow
      width="600px"
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Settings</h5>
        <div>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="showSettings = false"
          />
        </div>
      </div>

      <div class="p-2">
        <b-row>
          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <label class="bv-no-focus-ring h4 mb-0 p-0 mr-1 text-dark"
              >Allow Leaves</label
            >
            <b-form-checkbox v-model="leavesObj.enabled" switch />
          </b-col>
          <b-col md="6" v-if="leavesObj.enabled" class="mb-1">
            <b-form-group
              label="Category"
              invalid-feedback="Category is required."
              ref="all_cat"
            >
              <v-select
                ref="all_cat"
                v-model="leavesObj.option"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="bonusOptions"
                :reduce="(opt) => opt.text"
                label="text"
                :clearable="false"
                placeholder="Select"
                @input="setMaxLeaves()"
              />
            </b-form-group>
          </b-col>
          <b-col
            :md="leavesObj.option == 'Yearly' ? '3' : '6'"
            v-if="leavesObj.enabled"
          >
            <b-form-group
              label="Leave Allowed"
              invalid-feedback="Leave Allowed is required."
              ref="leave_allowed"
            >
              <b-form-input
                placeholder="Enter leave allowed"
                type="number"
                ref="leave_allowed"
                v-model="leavesObj.number"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="3"
            v-if="leavesObj.enabled && leavesObj.option == 'Yearly'"
            class="mb-50"
          >
            <b-form-group
              label="Max Leaves per Month"
              invalid-feedback="Max Allowed is required."
              ref="max_allowed"
            >
              <b-form-input
                placeholder="Enter max leaves per month"
                type="number"
                ref="max_allowed"
                v-model="maxObj.number"
              />
            </b-form-group>
          </b-col>

          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <label class="bv-no-focus-ring h4 mb-0 p-0 mr-1 text-dark"
              >Late Coming Deduction</label
            >
            <b-form-checkbox v-model="lateObj.enabled" switch />
          </b-col>
          <b-col md="6" v-if="lateObj.enabled" class="mb-1">
            <b-form-group
              label="Late Coming Scale"
              invalid-feedback="Invalid value entered"
              ref="lateScale"
            >
              <b-form-input
                placeholder="Enter late coming scale"
                ref="lateScale"
                type="number"
                v-model="lateObj.number"
                @input="setLateHint()"
              />
              <p
                v-if="showLateHint"
                class="mb-0 mt-50"
                style="font-size: 12px; line-height: 1rem"
              >
                ({{ lateObj.number ? lateObj.number : "n" }}) late comings equal
                to 1 absent
              </p>
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="lateObj.enabled" class="mb-1">
            <b-form-group label="Deduct For Every Late:">
              <b-form-checkbox
                v-model="lateObj.option"
                switch
                value="Yes"
                unchecked-value="No"
              />
            </b-form-group>
          </b-col>

          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <label class="bv-no-focus-ring h4 mb-0 p-0 mr-1 text-dark"
              >Late Coming Deduction (minutes)</label
            >
            <b-form-checkbox v-model="minuteObj.enabled" switch />
          </b-col>
          <b-col md="6" v-if="minuteObj.enabled" class="mb-1">
            <b-form-group
              label="Rupees per minute"
              invalid-feedback="Invalid value entered"
              ref="lateAmount"
            >
              <b-form-input
                placeholder="Enter rupees per minute"
                ref="lateAmount"
                type="number"
                v-model="minuteObj.number"
              />
            </b-form-group>
          </b-col>

          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <label class="bv-no-focus-ring h4 mb-0 p-0 mr-1 text-dark"
              >Short Leave Deduction</label
            >
            <b-form-checkbox v-model="shortObj.enabled" switch />
          </b-col>
          <b-col md="6" v-if="shortObj.enabled" class="mb-1">
            <b-form-group
              label="Short Leave Scale"
              invalid-feedback="Invalid value entered"
              ref="shortScale"
            >
              <b-form-input
                placeholder="Enter short leave scale"
                type="number"
                ref="shortScale"
                v-model="shortObj.number"
                @input="setHalfHint()"
              />
              <p
                v-if="showHalfHint"
                class="mb-0 mt-50"
                style="font-size: 12px; line-height: 1rem"
              >
                ({{ shortObj.number ? shortObj.number : "n" }}) short leaves
                equal to 1 absent
              </p>
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="shortObj.enabled" class="mb-1">
            <b-form-group label="Deduct For Every Short Leave:">
              <b-form-checkbox
                v-model="shortObj.option"
                switch
                value="Yes"
                unchecked-value="No"
              />
            </b-form-group>
          </b-col>

          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <label class="bv-no-focus-ring h4 mb-0 p-0 mr-1 text-dark"
              >Early Departure</label
            >
            <b-form-checkbox v-model="earlyObj.enabled" switch />
          </b-col>
          <b-col md="6" v-if="earlyObj.enabled" class="mb-1">
            <b-form-group
              label="Early Departure Scale"
              invalid-feedback="Invalid value entered"
              ref="earlyScale"
            >
              <b-form-input
                placeholder="Enter early departure scale"
                type="number"
                ref="earlyScale"
                v-model="earlyObj.number"
                @input="setEarlyHint()"
              />
              <p
                v-if="showEarly"
                class="mb-0 mt-50"
                style="font-size: 12px; line-height: 1rem"
              >
                ({{ earlyObj.number ? earlyObj.number : "n" }}) early departures
                equal to 1 absent
              </p>
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="earlyObj.enabled" class="mb-1">
            <b-form-group label="Deduct For Every Early Departure:">
              <b-form-checkbox
                v-model="earlyObj.option"
                switch
                value="Yes"
                unchecked-value="No"
              />
            </b-form-group>
          </b-col>

          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <label class="bv-no-focus-ring h4 mb-0 p-0 mr-1 text-dark"
              >100% Attendance Allowance</label
            >
            <b-form-checkbox v-model="attObj.enabled" switch />
          </b-col>
          <b-col md="6" v-if="attObj.enabled" class="mb-1">
            <b-form-group
              label="Based On"
              invalid-feedback="Attendance Allowance is required."
              ref="att_allow"
            >
              <v-select
                ref="att_allow"
                v-model="attObj.option"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="attTypes"
                :reduce="(opt) => opt.text"
                label="text"
                :clearable="false"
                placeholder="Select"
                @input="checkFields()"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" v-if="attObj.enabled" class="mb-1">
            <b-form-group
              v-if="attObj.option == 'Fixed amount'"
              label="Amount"
              invalid-feedback="Invalid amount entered"
              ref="amount"
            >
              <b-form-input
                placeholder="Enter amount"
                type="number"
                ref="amount"
                v-model="attObj.number"
              />
            </b-form-group>
            <b-form-group
              v-if="attObj.option == 'Percentage'"
              label="Percentage"
              invalid-feedback="Invalid value entered"
              ref="percentage"
            >
              <b-form-input
                placeholder="Enter percentage"
                type="number"
                ref="percentage"
                v-model="attObj.number"
              />
            </b-form-group>
            <!-- <b-form-group
              v-if="attObj.option == 'one'"
              label="No. of Days"
              invalid-feedback="Invalid value entered"
              ref="days"
            >
              <b-form-input
                placeholder="Enter no. of days"
                type="number"
                ref="days"
                v-model="attObj.number"
              />
            </b-form-group> -->
          </b-col>

          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <label class="bv-no-focus-ring h4 mb-0 p-0 mr-1 text-dark"
              >Leave Encashment</label
            >
            <b-form-checkbox v-model="encashObj.enabled" switch />
          </b-col>
          <b-col md="6" v-if="encashObj.enabled" class="mb-1">
            <b-form-group
              label="Maximum Encashment Leaves"
              invalid-feedback="Invalid value entered"
              ref="maxEn"
            >
              <b-form-input
                placeholder="Enter max no.of leaves"
                type="number"
                ref="maxEn"
                v-model="encashObj.number"
                @input="checkMax()"
              />
            </b-form-group>
          </b-col>

          <b-col
            class="d-flex align-items-center justify-content-between mb-1"
            md="12"
          >
            <label class="bv-no-focus-ring h4 mb-0 p-0 mr-1 text-dark"
              >Sandwich Policy</label
            >
            <b-form-checkbox v-model="sandwichObj.enabled" switch />
          </b-col>

          <b-col cols="12" class="mt-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="saveSettings()"
              :disabled="saving"
              block
            >
              <b-spinner v-if="saving" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </div>

      <sidebar-content />
    </b-sidebar>

    <b-modal
      id="modal-history"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      title="History"
    >
      <b-form>
        <b-form-group
          label="Salary Month"
          invalid-feedback="Salary Month is required."
          ref="his_month"
        >
          <v-select
            v-model="history.month"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="months"
            label="text"
            :reduce="(month) => month.value"
            :clearable="false"
            ref="month"
            placeholder="Select a month"
          />
        </b-form-group>

        <b-form-group
          label="Year"
          invalid-feedback="Year is required."
          ref="his_year"
        >
          <v-select
            v-model="history.year"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="years"
            :clearable="false"
            ref="his_year"
            placeholder="Select year"
          />
        </b-form-group>
      </b-form>
      <b-row class="mt-2">
        <b-col class="d-flex justify-content-end">
          <b-button
            @click="showHistory()"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
          >
            <span class="align-middle">Show</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="modal-payroll"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      :title="showMsg ? 'Whatsapp Pay Slip' : 'Payroll Report'"
    >
      <b-form>
        <b-form-group
          v-if="!showMsg"
          label="Report"
          invalid-feedback="Report is required."
          ref="pay_report"
        >
          <v-select
            v-model="payroll.report"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="reportOptions"
            label="text"
            :reduce="(opt) => opt.value"
            :clearable="false"
            ref="pay_report"
            placeholder="Select report"
          />
        </b-form-group>

        <b-form-group
          label="Departments"
          invalid-feedback="Department is required."
          ref="pay_depart"
        >
          <!-- multiple
        :closeOnSelect="false" -->
          <v-select
            v-model="payroll.depIDs"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="[{ name: 'All Departments', id: 0 }, ...departments]"
            :reduce="(opt) => opt.id"
            label="name"
            :clearable="false"
            placeholder="Select department"
          />
          <!-- @input="setDeps()" -->
        </b-form-group>

        <b-form-group
          label="Salary Month"
          invalid-feedback="Salary Month is required."
          ref="pay_month"
        >
          <v-select
            v-model="payroll.month"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="months"
            label="text"
            :reduce="(month) => month.value"
            :clearable="false"
            ref="pay_month"
            placeholder="Select a month"
          />
        </b-form-group>

        <b-form-group
          label="Year"
          invalid-feedback="Year is required."
          ref="pay_year"
        >
          <v-select
            v-model="payroll.year"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="years"
            :clearable="false"
            ref="pay_year"
            placeholder="Select year"
          />
        </b-form-group>

        <template v-if="!showMsg && payroll.report == 'bank'">
          <b-form-group
            label="Date"
            invalid-feedback="Date is required"
            ref="pay_date"
          >
            <flat-pickr
              ref="pay_date"
              :config="config"
              v-model="payroll.date"
              class="form-control"
              placeholder="Select date"
            />
          </b-form-group>

          <b-form-group
            label="Cheque Number"
            invalid-feedback="Cheque Number is required"
            ref="pay_cheq"
          >
            <b-form-input
              ref="pay_cheq"
              placeholder="Enter cheque number"
              v-model.trim="payroll.cheque"
            />
          </b-form-group>
        </template>
      </b-form>
      <b-row class="mt-2">
        <b-col class="d-flex align-items-start justify-content-end flex-wrap">
          <b-button
            v-if="showMsg"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="success"
            :disabled="sending"
            class=""
            @click="send()"
          >
            <b-spinner v-if="sending" small type="grow" />
            <span v-else> Send </span>
          </b-button>

          <template v-else>
            <b-button
              @click="showPayroll()"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              class="mb-50"
            >
              <span class="align-middle">Show</span>
            </b-button>
            <b-button
              @click="exportPayroll()"
              :disabled="exporting"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              class="ml-50 mb-50"
            >
              <span class="align-middle">Export</span>
            </b-button>
            <!-- <b-button
              @click="showPayroll('')"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              class="mb-50"
            >
              <span class="align-middle">Show Payroll</span>
            </b-button>
            <b-button
              @click="showPayroll('summary')"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              class="ml-50 mb-50"
            >
              <span class="align-middle">Show Summary</span>
            </b-button> -->
          </template>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="modal-returns"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      title="Return Deposites"
    >
      <b-form>
        <b-form-group
          label="Deductions"
          invalid-feedback="Deductions is required."
          ref="ret_deds"
        >
          <v-select
            multiple
            :closeOnSelect="false"
            v-model="returnIDs"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="deductions"
            :reduce="(opt) => opt.id"
            label="title"
            :clearable="false"
            placeholder="Select deductions"
          />
        </b-form-group>
      </b-form>
      <b-row class="mt-2">
        <b-col class="d-flex justify-content-end">
          <b-button
            @click="saveReturns()"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            :disabled="returnLoading"
          >
            <span class="align-middle">Save</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="modal-att"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      title="Edit Attendance"
    >
      <b-row class="">
        <b-col>
          <b-button
            pill
            size="sm"
            style="margin-inline: 2px"
            :variant="currentObj.status == 'P' ? 'success' : 'outline-success'"
            @click="currentObj.status = 'P'"
          >
            P
          </b-button>
          <b-button
            pill
            size="sm"
            style="margin-inline: 2px"
            :variant="currentObj.status == 'A' ? 'danger' : 'outline-danger'"
            @click="currentObj.status = 'A'"
          >
            A
          </b-button>
          <b-button
            v-if="doubleObj && doubleObj.valueBool == true"
            pill
            size="sm"
            style="margin-inline: 2px"
            :variant="currentObj.status == 'AA' ? 'danger' : 'outline-danger'"
            @click="currentObj.status = 'AA'"
          >
            AA
          </b-button>
          <b-button
            pill
            size="sm"
            style="margin-inline: 2px"
            :variant="currentObj.status == 'L' ? 'info' : 'outline-info'"
            @click="currentObj.status = 'L'"
          >
            L
          </b-button>
          <b-button
            pill
            size="sm"
            style="margin-inline: 2px"
            :variant="currentObj.status == 'SL' ? 'info' : 'outline-info'"
            @click="currentObj.status = 'SL'"
          >
            SL
          </b-button>
          <b-button
            pill
            size="sm"
            style="margin-inline: 2px"
            :variant="
              currentObj.status.toLowerCase() == 'late'
                ? 'warning'
                : 'outline-warning'
            "
            @click="currentObj.status = 'Late'"
          >
            Late
          </b-button>
          <b-button
            pill
            size="sm"
            style="margin-inline: 2px"
            :variant="currentObj.status == 'H' ? 'info' : 'outline-info'"
            @click="currentObj.status = 'H'"
          >
            H
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col class="d-flex justify-content-end">
          <b-button
            @click="saveAtt()"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="success"
            :disabled="savingAtt"
          >
            <b-spinner v-if="savingAtt" small type="grow" />
            <span v-else class="align-middle">Save</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility2"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility2 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12" class="mb-1 d-flex">
            <b-button
              v-for="t in typeOptions"
              :key="t"
              style="width: 50%"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :variant="
                t.value == loanObj.loanType ? 'primary' : 'outline-primary'
              "
              :disabled="loanObj.id > 0"
              class="mr-1"
              @click="loanObj.loanType = t.value"
            >
              {{ t.text }}
            </b-button>
          </b-col>
          <b-col md="12" v-if="false">
            <b-form-group
              label="Date"
              invalid-feedback="Date is required"
              ref="loan_date"
            >
              <flat-pickr
                :disabled="loanObj.id > 0"
                ref="loan_date"
                :config="config"
                v-model="loanObj.date"
                class="form-control"
                placeholder="Select date"
              />
              <!-- @on-change="checkLoanDate()" -->
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Amount"
              invalid-feedback="Amount is required"
              ref="loan_amount"
            >
              <b-form-input
                :disabled="loanObj.id > 0"
                ref="loan_amount"
                type="number"
                id="mc-first-name"
                placeholder="Enter amount"
                v-model="loanObj.amount"
                @input="checkLoanAmount()"
                @focusout="setInstallment()"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Return in months"
              invalid-feedback="Invalid Month"
              ref="loan_month"
            >
              <b-form-input
                :disabled="loanObj.id > 0"
                ref="loan_month"
                type="number"
                placeholder="Enter no. of months"
                v-model="loanObj.months"
                @input="checkMonth()"
                @focusout="setInstallment()"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Installment Amount"
              invalid-feedback="Invalid Amount"
              ref="return_amount"
            >
              <b-form-input
                :disabled="loanObj.id > 0"
                ref="return_amount"
                type="number"
                placeholder="Amount to return per month"
                v-model="loanObj.monthlyInstallments"
                @input="checkRetAmount()"
                @focusout="setbyRetAmount()"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col md="12">
            <b-form-group label="Deduct From" invalid-feedback="Invalid Amount">
              <v-select
                v-model="sameMonth"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="deductOption"
                label="text"
                :reduce="(opt) => opt.value"
                :clearable="false"
                placeholder="Select"
              />
            </b-form-group>
          </b-col> -->
          <b-col md="12">
            <label class="bv-no-focus-ring col-form-label pt-0"
              >Deduct From</label
            >
          </b-col>

          <b-col md="6">
            <b-form-group label="" invalid-feedback="Invalid Amount">
              <v-select
                v-model="loanMonth"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="months"
                label="text"
                :reduce="(opt) => opt.value"
                :clearable="false"
                placeholder="Select"
                :disabled="loanObj.id > 0"
                @input="setInstallment()"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="" invalid-feedback="Invalid Amount">
              <v-select
                v-model="loanYear"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="years"
                :clearable="false"
                placeholder="Select"
                :disabled="loanObj.id > 0"
                @input="setInstallment()"
              />
            </b-form-group>
          </b-col>

          <b-table
            class="mt-50"
            :items="installData"
            :fields="installFields"
            show-empty
            responsive
            hover
            small
          >
            <template #cell(month)="data">
              <b-badge variant="light-primary">
                {{ data.item.month }} {{ data.item.year }}
              </b-badge>
            </template>
            <template #cell(amount)="data">
              <b-badge variant="light-primary">
                Rs.{{ data.item.amount }}
              </b-badge>
              <b-badge
                v-if="data.item.isReturned"
                variant="light-success"
                class="ml-1"
              >
                Returned
              </b-badge>
            </template>
          </b-table>

          <b-col class="mt-1" md="12" v-if="loanObj.id == 0">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              @click="AddLoan()"
              block
              :disabled="loanSaving || installLoading"
            >
              <b-spinner v-if="loanSaving" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility5"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Add Deposit</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility5 = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col cols="12">
            <b-form-group
              label="Deposit Amount"
              invalid-feedback="Amount is required"
              ref="depo_amount"
            >
              <b-form-input
                ref="depo_amount"
                type="number"
                id="mc-first-name"
                placeholder="Enter amount"
                v-model="depoObj.amount"
                @input="checkDAmount()"
                @focusout="setDInstallment()"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Deposit Months"
              invalid-feedback="Invalid Month"
              ref="depo_month"
            >
              <b-form-input
                ref="depo_month"
                type="number"
                placeholder="Enter no. of months"
                v-model="depoObj.months"
                @input="checkDMonth()"
                @focusout="setDInstallment()"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Amount"
              invalid-feedback="Invalid Amount"
              ref="depo_return"
            >
              <b-form-input
                ref="depo_return"
                type="number"
                placeholder="Amount to return per month"
                v-model="depoObj.monthlyInstallments"
                @input="checkDRetAmount()"
                @focusout="setbyDReturn()"
              />
            </b-form-group>
          </b-col>

          <!-- <b-col md="12">
            <b-form-group label="Deduct From" invalid-feedback="Invalid Amount">
              <v-select
                v-model="sameMonth"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="deductOption"
                label="text"
                :reduce="(opt) => opt.value"
                :clearable="false"
                placeholder="Select"
              />
            </b-form-group>
          </b-col> -->
          <b-col md="12">
            <label class="bv-no-focus-ring col-form-label pt-0"
              >Deduct From</label
            >
          </b-col>

          <b-col md="6">
            <b-form-group label="" invalid-feedback="Invalid Amount">
              <v-select
                v-model="deductMonth"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="months"
                label="text"
                :reduce="(opt) => opt.value"
                :clearable="false"
                placeholder="Select"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="" invalid-feedback="Invalid Amount">
              <v-select
                v-model="deductYear"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="years"
                :clearable="false"
                placeholder="Select"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <label class="bv-no-focus-ring col-form-label pt-0 pb-1"
              >Skip Months</label
            >
          </b-col>
          <b-col md="12">
            <b-button
              class="btn-icon mr-50 mb-1"
              v-for="mon in months"
              :key="mon.value"
              :variant="skipped.includes(mon.value) ? 'danger' : 'primary'"
              @click="setSkip(mon.value)"
            >
              {{ mon.text.slice(0, 3) }}
            </b-button>
          </b-col>

          <b-col class="mt-1" md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              @click="AddDeposit()"
              block
              :disabled="savingDepo"
            >
              <b-spinner v-if="savingDepo" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-card>
      <b-row class="mt-1">
        <b-col
          class="mt-lg-0 mt-md-0 mt-sm-1 mt-1 d-flex"
          :xl="isHistory ? '3' : '6'"
          :lg="isHistory ? '3' : '6'"
          :md="isHistory ? '12' : '8'"
          sm="12"
          cols="12"
        >
          <feather-icon
            icon="ArrowLeftIcon"
            size="28"
            class="mr-50 mt-50 cursor-pointer"
            v-if="isLoan || isDeposit || showAtt"
            @click="
              isLoan = false;
              isDeposit = false;
              showAtt = false;
            "
            v-b-tooltip.hover.top
            title="Back"
          />
          <b-form-group class="w-100">
            <b-input-group>
              <b-input-group-prepend
                is-text
                @click="!isHistory && loadSearch()"
              >
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input
                v-model="searchQuery"
                placeholder="Search..."
                @keyup.enter="!isHistory && loadSearch()"
              >
              </b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col
          xl="3"
          lg="3"
          :md="isHistory ? '6' : '4'"
          sm="12"
          cols="12"
          class="mb-md-0 mb-1"
        >
          <v-select
            v-model="currentDep"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="[{ name: 'All Departments', id: 0 }, ...departments]"
            :reduce="(opt) => opt.id"
            label="name"
            :clearable="false"
            placeholder="Select department"
            @input="isHistory ? openHistory() : LoadData()"
          />
          <!-- @input="openHistory()" -->
        </b-col>

        <b-col
          xl="3"
          lg="3"
          md="6"
          sm="12"
          cols="12"
          class="mb-lg-0 mb-1 d-flex align-items-start"
          v-if="isHistory"
          style="gap: 0.95rem"
        >
          <v-select
            v-model="history.month"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="months"
            label="text"
            :reduce="(month) => month.value"
            :clearable="true"
            placeholder="Select month"
            style="flex: 1"
            @input="filterHistory()"
          />
          <v-select
            v-model="history.year"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="text"
            :options="years"
            :clearable="true"
            placeholder="Select year"
            style="flex: 1"
            @input="filterHistory()"
          />
        </b-col>

        <b-col
          xl="3"
          lg="3"
          md="12"
          sm="12"
          cols="12"
          class="d-flex align-items-start mb-md-0 mb-1"
        >
          <b-button
            v-if="isHistory"
            @click="LoadData()"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-50"
          >
            <feather-icon icon="XIcon" class="mr-50" />
            <span class="align-middle">Clear</span>
          </b-button>
          <b-button
            v-else
            @click="openHistory()"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-50"
          >
            <span class="align-middle">History</span>
          </b-button>
          <b-button
            @click="openPayroll()"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-50"
          >
            <span class="align-middle">Payroll Report</span>
          </b-button>
          <b-button
            @click="openSetting()"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon mr-50"
            :disabled="settLoading"
            v-b-tooltip.hover.top
            title="Settings"
          >
            <b-spinner
              v-if="settLoading"
              small
              variant="primary"
              label="Spinning"
            ></b-spinner>
            <feather-icon v-else icon="SettingsIcon" class="" />
          </b-button>
          <b-button
            @click="openPayroll('msg')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon"
            v-b-tooltip.hover.top
            title="Whatsapp Pay Slip"
          >
            <feather-icon icon="MessageCircleIcon" class="" />
          </b-button>
        </b-col>
      </b-row>

      <b-row class="mt-1" v-if="isDeposit || isLoan">
        <b-col v-if="otherLoading">
          <b-row>
            <b-col class="text-center">
              <b-spinner
                style="width: 3rem; height: 3rem"
                variant="primary"
                label="Spinning"
              ></b-spinner>
            </b-col>
          </b-row>
        </b-col>

        <b-col md="12" v-else class="mb-1">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="44"
                :src="currentStaff.staff.picture"
                variant="light-primary"
              />
            </template>

            <h4 class="mb-0">
              {{ currentStaff.staff.name }}
              {{ getDepName() }}
            </h4>
            <b-link class="font-weight-bold d-block text-nowrap">
              {{ currentStaff.staff.designation }}
            </b-link>
          </b-media>
        </b-col>

        <b-col md="12" v-if="isDeposit && !otherLoading">
          <b-row>
            <b-col xl="3" lg="3" md="6" sm="6">
              <!-- bg-variant="light-secondary" -->
              <b-card>
                <h4 class="card-title">Salary</h4>
                <p class="card-text font-small-6 font-weight-bolder mb-0">
                  <span>Rs.</span>
                  <span class="font-weight-bolder mb-0">
                    <!-- {{ totalDeposits }} -->
                    {{ currentStaff.staff.totalSalary }}
                  </span>
                </p>
              </b-card>
            </b-col>
            <b-col xl="3" lg="3" md="6" sm="6">
              <b-card>
                <h4 class="card-title">Deposited so far</h4>
                <p class="card-text font-small-6 font-weight-bolder mb-0">
                  <span>Rs.</span
                  ><span class="font-weight-bolder mb-0">
                    {{ currentDeposits }}</span
                  >
                </p>
              </b-card>
            </b-col>
            <b-col xl="3" lg="3" md="6" sm="6">
              <b-card>
                <h4 class="card-title">Remaining Deposits</h4>
                <p
                  class="card-text font-small-6 font-weight-bolder mb-0 d-flex justify-content-between"
                >
                  <span class="font-weight-bolder mb-0">
                    Rs. {{ remainingDeposits }}</span
                  >
                  <b-badge
                    variant="light-danger"
                    v-if="currentStaff.staff.totalSalary > totalDeposits"
                    >{{
                      currentStaff.staff.totalSalary - totalDeposits
                    }}</b-badge
                  >
                </p>
              </b-card>
            </b-col>
            <b-col xl="3" lg="3" md="6" sm="6">
              <b-card>
                <h4 class="card-title">Returned Deposits</h4>
                <p class="card-text font-small-6 font-weight-bolder mb-0">
                  <span>Rs.</span
                  ><span class="font-weight-bolder mb-0">
                    {{ returnDeposits }}</span
                  >
                </p>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-1" v-if="false">
        <b-col v-if="otherLoading">
          <b-row>
            <b-col class="text-center">
              <b-spinner
                style="width: 3rem; height: 3rem"
                variant="primary"
                label="Spinning"
              ></b-spinner>
            </b-col>
          </b-row>
        </b-col>

        <b-col v-else>
          <b-row>
            <b-col xl="3" lg="3" md="6" sm="6">
              <!-- bg-variant="light-secondary" -->
              <b-card>
                <h4 class="card-title">Salary</h4>
                <p class="card-text font-small-6 font-weight-bolder mb-0">
                  <span>Rs.</span>
                  <span class="font-weight-bolder mb-0">
                    <!-- {{ totalDeposits }} -->
                    {{ currentStaff.staff.totalSalary }}
                  </span>
                </p>
              </b-card>
            </b-col>
            <b-col xl="3" lg="3" md="6" sm="6">
              <b-card>
                <h4 class="card-title">Deposited so far</h4>
                <p class="card-text font-small-6 font-weight-bolder mb-0">
                  <span>Rs.</span
                  ><span class="font-weight-bolder mb-0">
                    {{ currentDeposits }}</span
                  >
                </p>
              </b-card>
            </b-col>
            <b-col xl="3" lg="3" md="6" sm="6">
              <b-card>
                <h4 class="card-title">Remaining Deposits</h4>
                <p
                  class="card-text font-small-6 font-weight-bolder mb-0 d-flex justify-content-between"
                >
                  <span class="font-weight-bolder mb-0">
                    Rs. {{ remainingDeposits }}</span
                  >
                  <b-badge
                    variant="light-danger"
                    v-if="currentStaff.staff.totalSalary > totalDeposits"
                    >{{
                      currentStaff.staff.totalSalary - totalDeposits
                    }}</b-badge
                  >
                </p>
              </b-card>
            </b-col>
            <b-col xl="3" lg="3" md="6" sm="6">
              <b-card>
                <h4 class="card-title">Returned Deposits</h4>
                <p class="card-text font-small-6 font-weight-bolder mb-0">
                  <span>Rs.</span
                  ><span class="font-weight-bolder mb-0">
                    {{ returnDeposits }}</span
                  >
                </p>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col>
          <b-button
            v-if="isLoan"
            @click="LoanOpen()"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Advance / Loan</span>
          </b-button>
          <b-col
            md="12"
            class="d-flex justify-content-between flex-wrap"
            v-else-if="isDeposit"
          >
            <b-button
              @click="depoOpen()"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">Security Deposit</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-50 mt-sm-0"
              :disabled="delAll"
              @click="depoDelAll()"
            >
              <span class="align-middle">Delete undeposited</span>
            </b-button>
          </b-col>
        </b-col>
      </b-row>

      <!-- loadn -->
      <b-table
        v-if="isLoan"
        class="mt-1"
        :busy="otherLoading"
        :items="loanData"
        :fields="loanFields"
        show-empty
        responsive
        hover
      >
        <template #empty="scope">
          <h3 class="mt-1 mb-1" style="text-align: center">No records found</h3>
        </template>
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>

        <template #cell(date)="data">
          <b-badge variant="light-primary">
            {{
              new Date(data.value).toLocaleDateString("en-UK", {
                year: "numeric",
                day: "numeric",
                month: "short",
              })
            }}
          </b-badge>
        </template>
        <template #cell(amount)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(loanType)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(months)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <div class="">
            <!-- v-if="data.item.id > 0" -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon mr-50"
              @click="EditLoan(data.item, data.index)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              class="btn-icon mr-50"
              @click="DeleteLoan(data.item, data.index)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
      </b-table>

      <!-- deposit -->
      <b-table
        v-else-if="isDeposit"
        class="mt-1"
        :busy="otherLoading"
        :items="depositDetails"
        :fields="depoFields"
        show-empty
        responsive
        hover
      >
        <template #empty="scope">
          <h3 class="mt-1 mb-1" style="text-align: center">No records found</h3>
        </template>
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>

        <template #cell(index)="data">
          <b-badge variant="light-primary">
            {{ data.index + 1 }}
          </b-badge>
        </template>
        <template #cell(date)="data">
          <b-badge variant="light-primary">
            {{ data.item.month }} {{ data.item.year }}
          </b-badge>
        </template>
        <template #cell(amount)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
          <!-- <b-badge
            v-if="data.item.isReturned"
            variant="light-success"
            class="ml-1"
          >
            Returned
          </b-badge>
          <b-badge
            v-else-if="data.item.salaryID"
            variant="light-success"
            class="ml-1"
          >
            Deposited
          </b-badge> -->
        </template>
        <template #cell(actions)="data">
          <template>
            <b-button
              v-if="data.item.salaryID != null"
              variant="success"
              class="btn-icon mr-1"
              pill
              size="sm"
              @click="markUnDeposit(data.item)"
            >
              Deposited
            </b-button>
            <b-button
              v-else
              variant="outline-success"
              class="btn-icon mr-1"
              pill
              size="sm"
              @click="markDeposit(data.item)"
            >
              Deposit
            </b-button>
            <b-button
              v-if="
                data.item.isReturned != null && data.item.isReturned == true
              "
              variant="success"
              class="btn-icon mr-1"
              pill
              size="sm"
              @click="markUnReturned(data.item)"
            >
              Returned
            </b-button>
            <b-button
              v-else-if="
                data.item.isReturned == null && data.item.salaryID != null
              "
              variant="outline-success"
              class="btn-icon mr-1"
              pill
              size="sm"
              @click="markReturned(data.item)"
            >
              Returned
            </b-button>
          </template>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            class="btn-icon mr-50"
            @click="DeleteDepo(data.item.id, data.index)"
            v-b-tooltip.hover.left
            title="Delete"
          >
            <feather-icon icon="Trash2Icon" />
          </b-button>
        </template>
      </b-table>

      <!-- history -->
      <b-table
        v-else-if="isHistory"
        class="mt-1"
        :busy="otherLoading"
        :items="historySearchFilters"
        :fields="hisFields"
        show-empty
        responsive
        hover
        small
      >
        <!-- :items="historyData" -->
        <template #empty="scope">
          <h3 class="mt-1 mb-1" style="text-align: center">No records found</h3>
        </template>

        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>
        <template #cell(index)="data">
          <b-badge variant="light-primary">
            <!-- {{ historyData.indexOf(data.item) + 1 }} -->
            {{ historySearchFilters.indexOf(data.item) + 1 }}
          </b-badge>
        </template>
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="44"
                :src="data.item.st.picture"
                variant="light-primary"
              />
            </template>

            <b-link
              class="font-weight-bold d-block text-nowrap"
              style="margin-bottom: 4px"
            >
              <b-badge variant="light-primary">
                {{ data.item.st.name }}
              </b-badge>
            </b-link>
            <b-link
              class="font-weight-bold d-block text-nowrap"
              style="margin-bottom: 4px"
            >
              <b-badge variant="light-primary">
                F/N: {{ data.item.st.father_name }}
              </b-badge>
            </b-link>
            <b-link class="font-weight-bold d-block text-nowrap">
              <b-badge variant="light-primary">
                {{ getDep(data.item.st.departmentID) }}
              </b-badge>
            </b-link>
          </b-media>
        </template>

        <template #cell(date)="data">
          <b-badge variant="light-primary">
            {{
              new Date(data.item.us.date).toLocaleDateString("en-UK", {
                year: "numeric",
                day: "numeric",
                month: "short",
              })
            }}
          </b-badge>
        </template>

        <template #cell(forDate)="data">
          <b-badge variant="light-primary">
            {{
              months
                .find((el) => el.value == data.item.us.month)
                .text.slice(0, 3)
            }}
            {{ data.item.us.year }}
          </b-badge>
        </template>

        <template #cell(basic)="data">
          <b-badge variant="light-primary">
            {{ data.item.us.basicSalary }}
          </b-badge>
        </template>
        <template #cell(allownaces)="data">
          <b-badge variant="light-primary">
            {{ data.item.us.totalAllownaces - data.item.us.depositReturn }}
          </b-badge>
        </template>
        <template #cell(securityDeposit)="data">
          <b-badge variant="light-primary">
            {{ data.item.us.securityDeposit }}
          </b-badge>
        </template>
        <template #cell(remainingDeposit)="data">
          <b-badge variant="light-primary">
            {{ data.item.us.remainingDeposit }}
          </b-badge>
        </template>
        <template #cell(depositUpto)="data">
          <b-badge variant="light-primary">
            {{ data.item.us.depositUpto }}
          </b-badge>
        </template>
        <template #cell(depositReturn)="data">
          <b-badge variant="light-primary">
            {{ data.item.us.depositReturn }}
          </b-badge>
        </template>
        <template #cell(absentDeduction)="data">
          <b-badge variant="light-primary">
            {{ data.item.us.absentDeduction }}
          </b-badge>
        </template>
        <template #cell(lateDeduction)="data">
          <b-badge variant="light-primary">
            {{ data.item.us.lateDeduction }}
          </b-badge>
        </template>
        <template #cell(totalDedcutions)="data">
          <b-badge variant="light-primary">
            {{ data.item.us.totalDedcutions }}
          </b-badge>
        </template>
        <template #cell(working)="data">
          <b-badge variant="light-primary">
            {{ data.item.us.workingDays }}
          </b-badge>
        </template>
        <template #cell(present)="data">
          <b-badge variant="light-primary">
            {{ data.item.us.present }}
          </b-badge>
        </template>
        <template #cell(absent)="data">
          <b-badge variant="light-primary">
            {{ data.item.us.absent }}
          </b-badge>
        </template>
        <template #cell(leave)="data">
          <b-badge variant="light-primary">
            {{ data.item.us.leave }}
          </b-badge>
        </template>
        <template #cell(late)="data">
          <b-badge variant="light-primary">
            {{ data.item.us.late }}
          </b-badge>
        </template>
        <template #cell(netSalary)="data">
          <b-badge variant="light-primary">
            {{ data.item.us.netSalary }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex">
            <b-button
              v-if="rights.edit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon mr-0 mr-sm-50 mb-50 mb-sm-0"
              @click="EditSalary(data.item, data.item.st.departmentID)"
              v-b-tooltip.hover.left
              title="Edit"
            >
              <feather-icon icon="EditIcon" class="" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon mr-0 mr-sm-50 mb-50 mb-sm-0"
              @click="printSlip(data.item.us.id)"
              v-b-tooltip.hover.left
              title="Print"
            >
              <feather-icon icon="PrinterIcon" class="" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon mr-0 mr-sm-50 mb-50 mb-sm-0"
              @click="sendSingle(data.item.us.id)"
              v-b-tooltip.hover.left
              title="Whatsapp Pay Slip"
              :disabled="singleSending && currentID == data.item.us.id"
            >
              <b-spinner
                v-if="singleSending && currentID == data.item.us.id"
                small
                variant="primary"
                label=""
              ></b-spinner>
              <feather-icon v-else icon="MailIcon" />
            </b-button>
            <b-button
              v-if="rights.delete"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              class="btn-icon mr-0 mb-50 mb-sm-0"
              @click="DeleteSal(data.item.us.id)"
              v-b-tooltip.hover.left
              title="Delete"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
      </b-table>

      <!-- calendar -->
      <b-row v-else-if="showAtt">
        <b-col md="12" class="mb-1">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="44"
                :src="stCurrent.picture"
                variant="light-primary"
              />
            </template>

            <h4 class="mb-0">
              {{ stCurrent.name }} - {{ getDep(stCurrent.departmentID) }}
            </h4>
            <b-link class="font-weight-bold d-block text-nowrap">
              {{ stCurrent.designation }}
            </b-link>
          </b-media>
        </b-col>

        <b-col class="d-flex align-items-start flex-wrap">
          <b-button
            class="btn-icon mr-50 mb-1"
            v-for="mon in months"
            :key="mon.value"
            :variant="attMonth == mon.value ? 'primary' : 'outline-primary'"
            @click="change(mon.value)"
          >
            {{ mon.text.slice(0, 3) }}
          </b-button>
          <div style="width: 120px">
            <b-form-group label="">
              <v-select
                v-model="attYear"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="years"
                :clearable="false"
                placeholder="Select year"
                @input="loadAtt()"
              />
            </b-form-group>
          </div>
        </b-col>

        <b-col md="12" class="text-center" v-if="attLoading">
          <b-spinner
            style="width: 3rem; height: 3rem"
            type="grow"
            variant="primary"
            label="Spinning"
            class="mt-5 mb-5"
          ></b-spinner>
        </b-col>

        <b-col md="12" v-else>
          <b-row class="hover-card mt-1">
            <b-col md="3">
              <b-card bg-variant="success" text-variant="white" class="">
                <h4 class="text-white font-weight-bolder">Present</h4>
                <p class="font-weight-bolder mb-0 text-right summary-count">
                  {{ summaryObj.present }}
                </p>
              </b-card>
            </b-col>
            <b-col md="3">
              <b-card bg-variant="danger" text-variant="white" class="">
                <h4 class="text-white font-weight-bolder">Absent</h4>
                <p class="font-weight-bolder mb-0 text-right summary-count">
                  {{ summaryObj.absent }}
                </p>
              </b-card>
            </b-col>
            <b-col md="3">
              <b-card bg-variant="info" text-variant="white" class="">
                <h4 class="text-white font-weight-bolder">Leave</h4>
                <p class="font-weight-bolder mb-0 text-right summary-count">
                  {{ summaryObj.leave }}
                </p>
              </b-card>
            </b-col>
            <b-col md="3">
              <b-card bg-variant="warning" text-variant="white" class="">
                <h4 class="text-white font-weight-bolder">Late</h4>
                <p class="font-weight-bolder mb-0 text-right summary-count">
                  {{ summaryObj.late }}
                </p>
              </b-card>
            </b-col>
          </b-row>
          <full-calendar
            ref="refCalendar"
            :options="calendarOptions"
            class="full-calendar mt-1"
          >
            <template v-slot:eventContent="arg">
              <b class="font-large-3">{{ arg.event.title }}</b>
              <p
                v-if="arg.event.extendedProps.timeIn"
                class="font-small-2 mb-25"
              >
                <span class="text-dark text-uppercase">Time in: </span>
                <b-badge
                  :variant="getVariant(arg.event.title)"
                  class="font-small-2"
                >
                  {{
                    new Intl.DateTimeFormat("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                    }).format(new Date(arg.event.extendedProps.timeIn))
                  }}
                </b-badge>
              </p>
              <p
                v-if="arg.event.extendedProps.timeOut"
                class="font-small-2 mb-0"
              >
                <span class="text-dark text-uppercase">Time out: </span>
                <b-badge
                  :variant="getVariant(arg.event.title)"
                  class="font-small-2"
                >
                  {{
                    new Intl.DateTimeFormat("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                    }).format(new Date(arg.event.extendedProps.timeOut))
                  }}
                </b-badge>
              </p>
            </template>
          </full-calendar>
        </b-col>
      </b-row>

      <!-- all staffs -->
      <!-- :items="filters" -->
      <b-table
        v-else
        class="mt-1"
        :busy="dataLoading"
        ref="selectableTable"
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        show-empty
        responsive
        hover
        small
      >
        <template #empty="scope">
          <h3 class="mt-1 mb-1" style="text-align: center">No records found</h3>
        </template>
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>

        <template #cell(index)="data">
          <b-badge variant="light-primary">
            <!-- {{ filters.indexOf(data.item) + 1 }} -->
            {{ (currentPage - 1) * perPage + data.index + 1 }}
          </b-badge>
        </template>

        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="44"
                :src="data.item.picture"
                variant="light-primary"
              />
            </template>

            <b-link
              class="font-weight-bold d-block text-nowrap"
              style="margin-bottom: 4px"
            >
              <b-badge variant="light-primary">
                {{ data.item.name }}
              </b-badge>
            </b-link>
            <b-link
              class="font-weight-bold d-block text-nowrap"
              style="margin-bottom: 4px"
            >
              <b-badge variant="light-primary">
                F/N: {{ data.item.father_name }}
              </b-badge>
            </b-link>
            <b-link
              v-if="reportDomain == 'myskoolhpgs'"
              class="font-weight-bold d-block text-nowrap"
            >
              <b-badge variant="light-primary">
                {{ getCampus(data.item.campusID) }}
              </b-badge>
            </b-link>
          </b-media>
        </template>

        <template #cell(designation)="data">
          <b-badge variant="light-primary">
            {{ data.item.designation }}
          </b-badge>
        </template>
        <template #cell(status)="data">
          <b-badge variant="light-primary">
            {{ data.item.status }}
          </b-badge>
        </template>
        <template #cell(appointedOn)="data">
          <b-badge variant="light-primary" v-if="data.item.appointedOn">
            {{
              new Date(data.item.appointedOn).toLocaleDateString("en-UK", {
                year: "numeric",
                day: "numeric",
                month: "short",
              })
            }}
          </b-badge>
        </template>

        <template #head(actions)="data">
          <div class="text-center">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex align-items-center">
            <b-button
              v-if="rights.add"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="Edit(data.item, data.item.departmentID)"
              v-b-tooltip.hover.left
              title="Create Salary"
            >
              <feather-icon icon="PlusIcon" class="" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              v-b-tooltip.hover.left
              title="View History"
              @click="singleHistory(data.item.id)"
            >
              <feather-icon icon="EyeIcon" class="" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              v-b-tooltip.hover.left
              title="View Attendance"
              @click="openAtt(data.item)"
            >
              <feather-icon icon="CalendarIcon" class="" />
            </b-button>
            <b-button
              v-if="rights.add"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="showOthers(data.item.id, 'Security Deposit')"
            >
              Security Deposit
            </b-button>
            <b-button
              v-if="rights.add"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="showOthers(data.item.id, 'Advance / Loan')"
            >
              Advance / Loan
            </b-button>
          </div>
        </template>
      </b-table>

      <b-col
        cols="12"
        class="d-flex align-items-center justify-content-center"
        v-if="!isLoan && !isDeposit && !isHistory && !showAtt"
      >
        <!-- :total-rows="filters.length" -->
        <b-pagination
          v-model="currentPage"
          :total-rows="totalCount"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @input="LoadData()"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BFormTextarea,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BForm,
  BMediaAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapActions, mapGetters } from "vuex";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import axios from "axios";

export default {
  components: {
    //Multiselect,
    flatPickr,
    BFormCheckboxGroup,
    BCard,
    BRow,
    BBadge,
    BFormTextarea,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,
    BMedia,
    BMediaAside,
    BDropdown,
    BDropdownItem,
    BAvatar,
    vSelect,
    FullCalendar,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    let right = {};
    this.$store.state.menu.forEach((el) => {
      el.children.forEach((ch) => {
        right[ch.route] = {
          view: ch.view,
          add: ch.add,
          edit: ch.edit,
          delete: ch.delete,
        };
      });
    });

    this.$store.commit("setRights", right);

    if (!this.$store.state.rights[this.$route.name]) {
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      // console.log(this.rights);
      this.LoadData();
      this.loadDep();
      // this.loadSettings();
      this.loadAllowances();
      this.loadDeductions();
      this.LoadAccounts();
      this.loadCOA();
      this.loadOther();
      if (this.reportDomain == "myskoolhpgs") {
        this.LoadCampuses();
      }
    }
  },
  computed: {
    filters: function () {
      return this.items.filter((pro) => {
        return (
          pro.name.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.id == this.searchQuery
        );
      });
    },
    historySearchFilters: function () {
      return this.historyData.filter((pro) => {
        return (
          pro.st.name.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.st.id == this.searchQuery
        );
      });
    },

    totalDeposits() {
      let total = this.depositDetails.reduce((sum, el) => sum + el.amount, 0);
      return total;
    },
    currentDeposits() {
      let total = this.depositDetails.reduce(
        (sum, el) => (el.salaryID != null ? sum + el.amount : sum + 0),
        0
      );
      return total;
    },
    remainingDeposits() {
      return this.totalDeposits - this.currentDeposits;
    },
    returnDeposits() {
      let total = this.depositDetails.reduce(
        (sum, el) =>
          el.isReturned != null && el.isReturned == true
            ? sum + el.amount
            : sum + 0,
        0
      );
      return total;
    },
  },
  data() {
    let yList = [];
    for (let i = 2020; i <= 2040; i++) {
      yList.push(i);
    }
    // console.log(yList);
    return {
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: "dayGridMonth",
        headerToolbar: {
          start: "title",
          end: "",
        },
        events: [
          // { title: "P", start: new Date() },
          // { title: "A", start: "2024-03-05T00:00:00" },
        ],
        eventResizableFromStart: true,
        dragScroll: true,
        dayMaxEvents: 2,
        // navLinks: true,
        eventClassNames({ event: e }) {
          const colorName =
            e.title == "P"
              ? "success"
              : e.title == "A" || e.title == "AA"
              ? "danger"
              : e.title == "L" || e.title == "H" || e.title == "SL"
              ? "info"
              : "warning";

          return [`text-${colorName}`];
        },
        eventClick: this.openEditAtt,
      },
      showAtt: false,
      attMonth: new Date().getMonth() + 1,
      attYear: new Date().getFullYear(),
      attLoading: false,
      stCurrent: {},
      summaryObj: {},
      currentObj: {
        status: "",
      },
      savingAtt: false,
      doubleObj: null,
      decimalData: [1, 2, 3],
      rights: {},
      skipped: [],
      delAll: false,
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      config2: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        mode: "range",
      },
      years: yList,
      months: [
        { text: "January", value: 1 },
        { text: "February", value: 2 },
        { text: "March", value: 3 },
        { text: "April", value: 4 },
        { text: "May", value: 5 },
        { text: "June", value: 6 },
        { text: "July", value: 7 },
        { text: "August", value: 8 },
        { text: "September", value: 9 },
        { text: "October", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
      ],
      deductMonth: new Date().getMonth() + 1,
      deductYear: new Date().getFullYear(),
      loanMonth: new Date().getMonth() + 1,
      loanYear: new Date().getFullYear(),
      request: false,
      requestCalculate: false,
      saving: false,
      colorDep: [],
      visibility1: false,
      sidebarTitle: "Employee's Salary",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,
      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 50,
      totalCount: 0,
      index: null,
      fields: [
        { label: "S.No", key: "index" },
        { label: "name", key: "name" },
        // { label: "father name", key: "father_name" },
        { label: "designation", key: "designation" },
        { label: "appointed on", key: "appointedOn" },
        // { label: "totalSalary", key: "totalSalary" },
        // { label: "status", key: "status" },
        "actions",
      ],
      items: [],
      selected: null,
      rangeDate: null,
      sal: {
        gross: null,
        totalDeduction: null,
        totalAllowance: null,
        netSalary: null,
        account: this.$store.state.accountType,
        date: new Date().toJSON(),
        month: this.$store.state.monthNo,
        year: this.$store.state.salYear,
      },
      deductInfo: [],
      AddingInfo: [],
      dedIDs: [],
      allowIDs: [],
      myObj: {},
      settObj: {
        isAllowed: false,
        leaveAllowed: 0,
        category: "",
        dateFrom: null,
        dateTo: null,
        onLate: false,
        lateScale: null,
        everyLate: false,
        onLeave: false,
        shortScale: null,
        everyLeave: false,
        onAttendance: false,
        attendanceType: "fixed",
        days: 0,
        amount: 0,
        percentage: 0,
        onEncashment: false,
        maxEncashment: 0,
      },
      settLoading: false,
      maxObj: {},
      minuteObj: {},
      earlyObj: {},
      sandwichObj: {},
      showEarly: true,
      leavesObj: {
        id: 0,
        tID: 0,
        categoy: "Leave Allowed",
        enabled: false,
        number: 0,
        option: "Monthly",
        campusID: this.$store.state.userData.cId,
      },
      lateObj: {
        id: 0,
        tID: 0,
        categoy: "Late Coming Scale",
        enabled: false,
        number: 0,
        option: "No",
        campusID: this.$store.state.userData.cId,
      },
      shortObj: {
        id: 0,
        tID: 0,
        categoy: "Short Leave Scale",
        enabled: false,
        number: 0,
        option: "No",
        campusID: this.$store.state.userData.cId,
      },
      attObj: {
        id: 0,
        tID: 0,
        categoy: "Attendance Allowance",
        enabled: false,
        number: 0,
        option: "Fixed amount",
        campusID: this.$store.state.userData.cId,
      },
      encashObj: {
        id: 0,
        tID: 0,
        categoy: "Leave Encashment",
        enabled: false,
        number: 0,
        option: "",
        campusID: this.$store.state.userData.cId,
      },
      settingData: [],
      bonusOptions: [
        { text: "Monthly", value: "monthly" },
        { text: "Yearly", value: "yearly" },
      ],
      attCount: {
        workingDays: 0,
        present: 0,
        absent: 0,
        leave: 0,
        late: 0,
        halfLeave: 0,
      },
      showHalfHint: true,
      showLateHint: true,
      attTypes: [
        { text: "Fixed amount", value: "fixed" },
        { text: "Percentage", value: "percentage" },
        { text: "One day salary", value: "one" },
      ],
      customObj: {
        show: true,
      },
      policyObj: [],
      leaveTypes: [
        { text: "Monthly", value: "monthly" },
        { text: "Yearly", value: "yearly" },
      ],
      salaryObj: {
        id: 0,
        details: "",
        amount: 0,
        salaryInfoID: 0,
        campusID: this.$store.state.userData.cId,
      },
      // deductObj: {
      //   id: 0,
      //   details: "",
      //   amount: 0,
      //   deductID: 0,
      //   campusID: this.$store.state.userData.cId,
      // },
      depObj: {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      },
      detailObj: {},
      timeout: 10000,
      showSettings: false,
      showDetails: false,
      accTypes: [],
      allowances: [],
      deductions: [],
      currentDep: 0,
      departments: [],
      history: {
        month: null,
        year: null,
      },
      payroll: {
        // depIDs: [],
        depIDs: 0,
        month: "",
        year: new Date().getFullYear(),
        date: new Date().toJSON().split("T")[0],
        cheque: "",
        report: "",
      },
      reportOptions: [
        { text: "Payroll", value: "" },
        { text: "Summary", value: "summary" },
        { text: "Bank Report", value: "bank" },
        { text: "Cash Salaries", value: "cash" },
        { text: "Salary Preparation Sheet", value: "preparation" },
        { text: "Salary Register", value: "register" },
        { text: "Salary Reconcilation Report", value: "reconcilation" },
        { text: "Salary Receiving Sheet", value: "receiving" },
      ],
      showMsg: false,
      isHistory: false,
      historyData: [],
      allHistory: [],
      hisFields: [
        { label: "S.No", key: "index" },
        { label: "name", key: "name" },
        { label: "Payment Date", key: "date" },
        { label: "Salary for", key: "forDate" },
        { label: "basic Salary", key: "basic" },
        { label: "current deposit", key: "securityDeposit" },
        { label: "remaining deposit", key: "remainingDeposit" },
        { label: "deposit upto", key: "depositUpto" },
        { label: "deposit return", key: "depositReturn" },
        { label: "working days", key: "working" },
        { label: "present", key: "present" },
        { label: "absent", key: "absent" },
        { label: "leave", key: "leave" },
        { label: "late", key: "late" },
        { label: "absent Deduction", key: "absentDeduction" },
        { label: "late Deduction", key: "lateDeduction" },
        { label: "allownaces", key: "allownaces" },
        { label: "dedcutions", key: "totalDedcutions" },
        { label: "net Salary", key: "netSalary" },
        { label: "actions", key: "actions" },
      ],
      tID: 0,
      currentDate: this.$store.state.salPaymentDate,
      mSalary: {
        allowances: [],
        deductions: [],
        advanceIDs: [],
        loanIDs: [],
        sdIDs: [],
        salary: {
          absent: 0,
          absentDeductionCount: 0,
          shortLeaveDeductionCount: 0,
          lateDeductionCount: 0,
          accountID: 0,
          basicSalary: 0,
          campusID: 0,
          date: this.$store.state.salPaymentDate,
          grossSalary: 0,
          id: 0,
          late: 0,
          leave: 0,
          month: 0,
          monthYear: "",
          netSalary: 0,
          note: null,
          present: 0,
          shortLeave: 0,
          tID: 0,
          totalAllownaces: 0,
          totalDedcutions: 0,
          workingDays: 0,
          year: 0,
          utilizeableLeaves: 0,
          remainingLeaves: 0,
          bonusDaysAmount: 0,
          bonusDays: 0,
          leavesEncashed: 0,
          leavesEncashementAmount: 0,
          securityDeposit: 0,
          loanDeduction: 0,
          advanceDeduction: 0,
          shortLeaveDeductionCount: 0,
          lateDeductionCount: 0,
        },
        maxLeavePerMonth: 0,
        remainingLeaves: 0,
        utilizeableLeaves: 0,
      },

      loanFields: [
        { label: "date", key: "date" },
        { label: "type", key: "loanType" },
        { label: "amount", key: "amount" },
        { label: "months", key: "months" },
        { label: "actions", key: "actions" },
      ],
      loanData: [],
      depoFields: [
        { label: "S.No", key: "index" },
        { label: "date", key: "date" },
        { label: "amount", key: "amount" },
        { label: "actions", key: "actions" },
      ],
      depositDetails: [],
      otherLoading: false,
      isLoan: false,
      isDeposit: false,
      reportDomain: this.$store.state.report.replace(/_/g, ""),

      returnIDs: [],
      returnLoading: false,

      currentStaff: {},
      installLoading: false,
      visibility2: false,
      loanObj: {},
      loanSaving: false,
      loanDetails: [],
      installData: [],
      installFields: [
        { label: "month", key: "month" },
        { label: "amount", key: "amount" },
      ],
      newLoan: false,
      typeOptions: [
        { text: "Advance", value: "advance" },
        { text: "Loan", value: "loan" },
      ],
      visibility5: false,
      depoSaveList: [],
      depoObj: {},
      savingDepo: false,
      newDepo: true,
      deductOption: [
        { text: "Same Month", value: true },
        { text: "Next Month", value: false },
      ],
      sameMonth: true,

      sunObj: null,
      sunmonValue: false,
      sunmonDepID: null,
      toExpense: true,
      expOptions: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      entryObj: null,
      coAccounts: [],
      accountObj: {
        id: 0,
        campusID: this.$store.state.userData.cId,
        account: "",
        status: "active",
        accountID: 0,
      },
      accountbar: false,
      accIcon: "",
      savingacc: false,
      accFields: [
        { label: "Name", key: "account" },
        { key: "actions", label: "actions" },
      ],
      sending: false,
      currentID: 0,
      singleSending: false,
      exporting: false,
      campuses: [],
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({
      get: "get",
      post: "post",
      postObj: "postObj",
      put: "put",
      delete: "delete",
    }),

    getCampus(id) {
      let obj = this.campuses.find((el) => el.id == id);
      return obj ? obj.branch : "";
    },
    async LoadCampuses() {
      var obj = {
        url:
          this.$store.state.domain +
          "campuses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.campuses = await this.get(obj);
    },

    getVariant(title) {
      const vt =
        title == "P"
          ? "success"
          : title == "A" || title == "AA"
          ? "danger"
          : title == "L" || title == "H" || title == "SL"
          ? "info"
          : "warning";

      return vt;
    },
    openAtt(item) {
      this.stCurrent = item;
      this.showAtt = true;
      this.attMonth = new Date().getMonth() + 1;
      this.attYear = new Date().getFullYear();
      this.loadAtt();
    },
    async loadAtt() {
      this.attLoading = true;
      let dt = `${this.attYear}-${this.attMonth
        .toString()
        .padStart(2, "0")}-01`;

      var obj = {
        url:
          this.$store.state.domain +
          "Staff/LoadAttendanceCalendar?db=" +
          this.$store.state.userData.db +
          "&tID=" +
          this.stCurrent.id +
          "&month=" +
          this.attMonth +
          "&year=" +
          this.attYear,
        token: this.$store.state.userData.token,
        dashboard: true,
      };
      let res = await this.get(obj);
      // console.log("res:", res);
      if (res.data.length > 0) {
        this.calendarOptions.events = res.data.map((el) => ({
          ...el,
          title: el.status,
          start: el.date,
        }));
      } else {
        this.calendarOptions.events = [];
      }
      this.summaryObj = res.summary;

      this.attLoading = false;
      setTimeout(() => {
        var elem = this.$refs.refCalendar.getApi();
        elem.gotoDate(dt);
      }, 200);
    },
    change(val) {
      this.attMonth = val;
      this.loadAtt();
    },
    openEditAtt({ event: clickedEvent }) {
      // console.log(clickedEvent);
      const { id, extendedProps, start } = clickedEvent;
      let dt = `${start.getFullYear()}-${(start.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${start.getDate().toString().padStart(2, "0")}`;

      this.currentObj = { ...extendedProps, id: parseInt(id), date: dt };
      // console.log(this.currentObj);
      this.$bvModal.show("modal-att");
    },
    async saveAtt() {
      this.savingAtt = true;
      var status = await this.put({
        url:
          this.$store.state.domain +
          "staffAttendance/" +
          this.currentObj.id +
          "?db=" +
          this.$store.state.userData.db,
        message: "Attendance updated successfully.",
        context: this,
        body: this.currentObj,
        token: this.$store.state.userData.token,
      });
      this.savingAtt = false;
      if (status) {
        this.loadAtt();
        this.$bvModal.hide("modal-att");
      }
    },

    getDep(id) {
      let obj = this.departments.find((el) => el.id == id);
      return obj ? obj.name : "";
    },

    async loadCOA() {
      var obj = {
        url:
          this.$store.state.domain +
          "Accounts/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.coAccounts = await this.get(obj);
      // console.log(this.coAccounts);
    },
    setCOA(el) {
      if (el == null || el == "") this.accountObj.accountID = 0;
    },
    openAccount() {
      this.accIcon = "PlusIcon";
      this.accountbar = true;
      this.accountObj = {
        id: 0,
        campusID: this.$store.state.userData.cId,
        account: "",
        status: "active",
        accountID: 0,
      };
      var elem = this.$refs["accname"];
      elem.state = undefined;
    },
    checkAccount() {
      var elem = this.$refs["accname"];
      if (this.accountObj.account == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async saveAcc() {
      if (this.checkAccount() == true) {
        this.savingacc = true;
        if (this.accountObj.id == 0) {
          var status = await this.post({
            url:
              this.$store.state.domain +
              "CollectionAccounts?db=" +
              this.$store.state.userData.db,
            body: this.accountObj,
            message: "Account added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
        } else {
          var status = await this.put({
            url:
              this.$store.state.domain +
              "CollectionAccounts/" +
              this.accountObj.id +
              "?db=" +
              this.$store.state.userData.db,

            message: "Account updated successfully.",
            context: this,
            body: this.accountObj,
            token: this.$store.state.userData.token,
          });
        }
        if (status) {
          this.LoadAccounts();
          this.accountObj = {
            id: 0,
            campusID: this.$store.state.userData.cId,
            account: "",
            status: "active",
            accountID: 0,
          };
          var elem = this.$refs["accname"];
          elem.state = undefined;
        }
        this.savingacc = false;
      }
    },
    async editAcc(acc) {
      this.accountObj = { ...acc };
      this.accIcon = "Edit2Icon";
      var elem = this.$refs["accname"];
      elem.state = undefined;
      // console.log(this.accountObj);
    },

    async deleteAcc(acc) {
      // console.log(acc);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "CollectionAccounts/" +
            acc.id +
            "?db=" +
            this.$store.state.userData.db,

          message: "Account deleted successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadAccounts();
      }
    },

    getDepName() {
      let obj = this.departments.find(
        (el) => el.id == this.currentStaff.staff.departmentID
      );
      return obj ? " - " + obj.name : " - ";
    },

    LoanOpen() {
      this.loanObj = {
        id: 0,
        tID: this.currentStaff.staff.id,
        amount: null,
        loanType: "advance",
        date: new Date(),
        months: null,
        monthlyInstallments: null,
        campusID: this.$store.state.userData.cId,
        records: [],
      };
      this.installData = [];

      this.loanMonth = new Date().getMonth() + 1;
      this.loanYear = new Date().getFullYear();

      this.visibility2 = true;
      this.newLoan = true;
      var elem = this.$refs["loan_amount"];
      elem.state = undefined;
      var elem = this.$refs["loan_month"];
      elem.state = undefined;
      var elem = this.$refs["return_amount"];
      elem.state = undefined;
    },

    checkLoanAmount() {
      var elem = this.$refs["loan_amount"];
      if (this.loanObj.amount <= 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkMonth() {
      var elem = this.$refs["loan_month"];
      if (this.loanObj.months <= 0) {
        // || this.loanObj.months > 12
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async setInstallment() {
      if (this.checkLoanAmount() == true && this.checkMonth() == true) {
        this.installLoading = true;

        this.loanObj.monthlyInstallments = parseInt(
          this.loanObj.amount / this.loanObj.months
        );

        // console.log(this.loanObj.monthlyInstallments);
        this.checkRetAmount();

        this.loanObj.amount = parseInt(this.loanObj.amount);
        this.loanObj.months = parseInt(this.loanObj.months);
        this.loanObj.monthlyInstallments = parseInt(
          this.loanObj.monthlyInstallments
        );

        this.loanObj.date = new Date(
          `${this.loanYear}-${this.loanMonth}-05`
        ).toJSON();
        // console.log(this.loanObj);
        var status = await this.post({
          url:
            this.$store.state.domain +
            "staff/Installments?db=" +
            this.$store.state.userData.db +
            "&sameMonth=" +
            this.sameMonth,
          body: this.loanObj,
          subjects: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        // console.log(status);
        if (status) {
          this.installData = status;
          this.loanObj.records = status;
        }

        this.installLoading = false;
      }
    },
    async setbyRetAmount() {
      if (this.checkLoanAmount() == true && this.checkRetAmount() == true) {
        this.installLoading = true;

        this.loanObj.amount = parseInt(this.loanObj.amount);
        // this.loanObj.months = parseInt(this.loanObj.months);

        this.loanObj.months = Math.ceil(
          this.loanObj.amount / this.loanObj.monthlyInstallments
        );

        // console.log(this.loanObj.months);
        this.checkMonth();

        this.loanObj.monthlyInstallments = parseInt(
          this.loanObj.monthlyInstallments
        );

        this.loanObj.date = new Date(
          `${this.loanYear}-${this.loanMonth}-05`
        ).toJSON();
        // console.log(this.loanObj);
        var status = await this.post({
          url:
            this.$store.state.domain +
            "staff/Installments?db=" +
            this.$store.state.userData.db +
            "&sameMonth=" +
            this.sameMonth,
          body: this.loanObj,
          subjects: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        // console.log(status);
        if (status) {
          this.installData = status;
          this.loanObj.records = status;
        }

        this.installLoading = false;
      }
    },
    checkRetAmount() {
      var elem = this.$refs["return_amount"];
      if (
        this.loanObj.monthlyInstallments <= 0 ||
        parseInt(this.loanObj.monthlyInstallments) >
          parseInt(this.loanObj.amount)
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async AddLoan() {
      // this.checkLoanDate() == true &&
      if (
        this.checkLoanAmount() == true &&
        this.checkMonth() == true &&
        this.checkRetAmount() == true
      ) {
        this.loanSaving = true;

        this.loanObj.amount = parseInt(this.loanObj.amount);
        this.loanObj.months = parseInt(this.loanObj.months);
        this.loanObj.monthlyInstallments = parseInt(
          this.loanObj.monthlyInstallments
        );

        this.loanData.push(this.loanObj);
        let newLoadDet = this.loanData.reduce((acc, el) => {
          if (el.records) {
            acc = [...acc, ...el.records];
          }
          return acc;
        }, []);
        // console.log(newLoadDet);

        let saveObj = {
          staff: this.currentStaff.staff,
          timings: this.currentStaff.timings,
          incrementHistory: this.currentStaff.incrementHistory,
          staffAllowances: this.currentStaff.staffAllowances,
          staffDeductions: this.currentStaff.staffDeductions,
          advanceAndLoans: this.loanData,
          policy: this.currentStaff.policy,
          loanDetails: [...this.loanDetails, ...newLoadDet],
          securityDeposit: this.currentStaff.securityDeposit,
          securityDepositGrid: this.currentStaff.securityDepositGrid,
        };

        var status = await this.postObj({
          url:
            this.$store.state.domain +
            "staff/SaveWithPolicy?db=" +
            this.$store.state.userData.db,
          body: saveObj,
          message: this.loanObj.loanType + " saved successfully",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.loanSaving = false;
        this.visibility2 = false;

        this.showOthers(this.currentStaff.staff.id, "Advance / Loan");
      }
    },
    EditLoan(item, ind) {
      // console.log(item);
      this.loanObj = { ...item };
      let date = new Date(this.loanObj.date);
      this.loanMonth = date.getMonth() + 1;
      this.loanYear = date.getFullYear();
      if (item.id == 0) this.installData = item.records;
      else
        this.installData = this.loanDetails.filter(
          (el) => el.advanceAndLoanID == item.id
        );
      this.loanIndex = ind;
      this.newLoan = false;
      this.visibility2 = true;

      var elem = this.$refs["loan_amount"];
      elem.state = undefined;
      var elem = this.$refs["loan_date"];
      elem.state = undefined;
      var elem = this.$refs["loan_month"];
      elem.state = undefined;
      var elem = this.$refs["return_amount"];
      elem.state = undefined;
    },

    async DeleteLoan(item, ind) {
      if (item.id == 0) {
        this.loanData.splice(ind, 1);
        // console.log(this.loanData);
      } else {
        let result = await Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes, Delete it!",
        });

        if (result.isConfirmed) {
          var status = await this.delete({
            url:
              this.$store.state.domain +
              "StaffAdvaceAndLoans/" +
              item.id +
              "?db=" +
              this.$store.state.userData.db,
            message: item.loanType + " removed successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          if (status) this.loanData.splice(ind, 1);
          // console.log(this.loanData);
        }
      }
    },

    depoOpen() {
      this.depoObj = {
        id: 0,
        tID: this.currentStaff.staff.id,
        amount: this.currentStaff.staff.totalSalary,
        loanType: "security deposit",
        date: new Date(),
        months: 12,
        monthlyInstallments: null,
        campusID: this.$store.state.userData.cId,
      };
      this.depoObj.monthlyInstallments = parseInt(
        this.depoObj.amount / this.depoObj.months
      );

      this.deductMonth = new Date().getMonth() + 1;
      this.deductYear = new Date().getFullYear();

      this.skipped = [];

      this.visibility5 = true;
      this.newDepo = true;
      var elem = this.$refs["depo_amount"];
      elem.state = undefined;
      var elem = this.$refs["depo_month"];
      elem.state = undefined;
      var elem = this.$refs["depo_return"];
      elem.state = undefined;
    },
    checkDAmount() {
      var elem = this.$refs["depo_amount"];
      if (this.depoObj.amount <= 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkDMonth() {
      var elem = this.$refs["depo_month"];
      if (this.depoObj.months <= 0) {
        // || this.depoObj.months > 12
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkDRetAmount() {
      var elem = this.$refs["depo_return"];
      if (
        this.depoObj.monthlyInstallments <= 0 ||
        parseInt(this.depoObj.monthlyInstallments) >
          parseInt(this.depoObj.amount)
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    setDInstallment() {
      if (this.checkDAmount() == true && this.checkDMonth() == true) {
        this.depoObj.monthlyInstallments = parseInt(
          this.depoObj.amount / this.depoObj.months
        );

        // console.log(this.depoObj.monthlyInstallments);
      }
    },
    setbyDReturn() {
      if (this.checkDAmount() == true && this.checkDRetAmount() == true) {
        this.depoObj.amount = parseInt(this.depoObj.amount);
        this.depoObj.monthlyInstallments = parseInt(
          this.depoObj.monthlyInstallments
        );

        this.depoObj.months = Math.ceil(
          this.depoObj.amount / this.depoObj.monthlyInstallments
        );
      }
    },
    setSkip(val) {
      if (this.skipped.includes(val)) {
        this.skipped = this.skipped.filter((el) => el != val);
      } else this.skipped.push(val);
      // console.log(this.skipped);
    },
    async AddDeposit() {
      if (
        this.checkDAmount() == true &&
        this.checkDMonth() == true &&
        this.checkDRetAmount() == true
      ) {
        this.savingDepo = true;

        this.depoObj.amount = parseInt(this.depoObj.amount);
        this.depoObj.months = parseInt(this.depoObj.months);
        this.depoObj.monthlyInstallments = parseInt(
          this.depoObj.monthlyInstallments
        );

        this.depoObj.date = new Date(
          `${this.deductYear}-${this.deductMonth}-05`
        ).toJSON();

        var status = await this.post({
          url:
            this.$store.state.domain +
            "staff/InstallmentsDep?db=" +
            this.$store.state.userData.db +
            "&sameMonth=true&skip=" +
            this.skipped,
          body: this.depoObj,
          subjects: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        // console.log(status);
        if (status) {
          this.depoSaveList.push(this.depoObj);

          let saveObj = {
            staff: this.currentStaff.staff,
            timings: this.currentStaff.timings,
            incrementHistory: this.currentStaff.incrementHistory,
            staffAllowances: this.currentStaff.staffAllowances,
            staffDeductions: this.currentStaff.staffDeductions,
            advanceAndLoans: this.currentStaff.advanceAndLoans,
            policy: this.currentStaff.policy,
            loanDetails: this.currentStaff.loanDetails,
            securityDeposit: this.depoSaveList,
            securityDepositGrid: [...this.depositDetails, ...status],
          };

          var status = await this.postObj({
            url:
              this.$store.state.domain +
              "staff/SaveWithPolicy?db=" +
              this.$store.state.userData.db,
            body: saveObj,
            message: "Deposits saved successfully",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.savingDepo = false;
          this.visibility5 = false;

          this.showOthers(this.currentStaff.staff.id, "Security Deposit");
        } else this.savingDepo = false;
      }
    },
    async markReturned(item) {
      if (item.id == 0) {
        item.isReturned = true;
      } else {
        item.isReturned = true;
        // console.log(item);

        var status = await this.put({
          url:
            this.$store.state.domain +
            "StaffAdvaceAndLoansInstallmentPlan/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          token: this.$store.state.userData.token,
          message: "Marked as Returned",
          context: this,
          body: item,
          token: this.$store.state.userData.token,
        });
        if (status) {
          console.log("done");
        }
      }
    },
    async markDeposit(item) {
      if (item.id == 0) {
        item.salaryID = 0;
      } else {
        item.salaryID = 0;

        var status = await this.put({
          url:
            this.$store.state.domain +
            "StaffAdvaceAndLoansInstallmentPlan/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          token: this.$store.state.userData.token,
          message: "Marked as Deposited",
          context: this,
          body: item,
          token: this.$store.state.userData.token,
        });
        if (status) {
          console.log("done");
        }
      }
    },
    async markUnDeposit(item) {
      if (item.id == 0) {
        item.salaryID = null;
        item.isReturned = null;
      } else {
        let result = await Swal.fire({
          title: "Are you sure?",
          text: "Do you want to undeposit this amount?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes!",
        });

        if (result.isConfirmed) {
          item.salaryID = null;
          item.isReturned = null;
          // console.log(item);

          var status = await this.put({
            url:
              this.$store.state.domain +
              "StaffAdvaceAndLoansInstallmentPlan/" +
              item.id +
              "?db=" +
              this.$store.state.userData.db,
            token: this.$store.state.userData.token,
            message: "Marked as Undeposit",
            context: this,
            body: item,
            token: this.$store.state.userData.token,
          });
          if (status) {
            console.log("done");
          }
        }
      }
    },
    async markUnReturned(item) {
      if (item.id == 0) {
        item.isReturned = null;
      } else {
        let result = await Swal.fire({
          title: "Are you sure?",
          text: "Do you want to unreturn this deposit?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes!",
        });

        if (result.isConfirmed) {
          item.isReturned = null;
          // console.log(item);

          var status = await this.put({
            url:
              this.$store.state.domain +
              "StaffAdvaceAndLoansInstallmentPlan/" +
              item.id +
              "?db=" +
              this.$store.state.userData.db,
            token: this.$store.state.userData.token,
            message: "Marked as unreturned",
            context: this,
            body: item,
            token: this.$store.state.userData.token,
          });
          if (status) {
            console.log("done");
          }
        }
      }
    },
    async DeleteDepo(id, ind) {
      if (id == 0) {
        this.depositDetails.splice(ind, 1);
        // console.log(this.depositDetails);
      } else {
        let result = await Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes, Delete it!",
        });

        if (result.isConfirmed) {
          var status = await this.delete({
            url:
              this.$store.state.domain +
              "StaffAdvaceAndLoansInstallmentPlan/" +
              id +
              "?db=" +
              this.$store.state.userData.db,
            message: "Deposit removed successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          if (status) this.depositDetails.splice(ind, 1);
          // console.log(this.depositDetails);
        }
      }
    },
    async depoDelAll() {
      if (this.depositDetails.length > 0) {
        let result = await Swal.fire({
          title: "Are you sure?",
          text: "This will delete all Undeposited amount!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes, Delete it!",
        });

        if (result.isConfirmed) {
          this.delAll = true;

          var status = await this.post({
            url:
              this.$store.state.domain +
              "Staff/ClearDeposits?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId +
              "&tID=" +
              this.currentStaff.staff.id,
            message: "Deposits removed successfully.",
            context: this,
            savingMarks: true,
            token: this.$store.state.userData.token,
          });
          this.delAll = false;

          this.depositDetails = status;
        }
      }
    },

    setAbsent() {
      let dCount = parseInt(this.mSalary.salary.workingDays);
      let pCount = parseInt(this.mSalary.salary.present);
      let aCount = parseInt(this.mSalary.salary.absent);
      this.mSalary.salary.absent = dCount - pCount;
      this.mSalary.salary.leave = 0;
    },
    setPresent() {
      if (this.checkWDays() == true && this.checkPresent() == true) {
        let dCount = parseInt(this.mSalary.salary.workingDays);
        let pCount = parseInt(this.mSalary.salary.present);

        if (pCount <= dCount) {
          this.mSalary.salary.absent = dCount - pCount;
        }
      }
    },
    setP() {
      let dCount = parseInt(this.mSalary.salary.workingDays);
      let aCount = parseInt(this.mSalary.salary.absent);
      let lCount = parseInt(this.mSalary.salary.leave);
      if (lCount <= dCount) {
        let che = dCount - aCount;
        this.mSalary.salary.present = che - lCount;
        console.log(this.mSalary.salary.absent);
      }
    },
    saveDate() {
      this.$store.commit("setSalPaymentDate", this.currentDate);
    },
    async LoadSalary() {
      this.$store.commit("setMonthNo", this.sal.month);
      this.$store.commit("setSalYear", this.sal.year);
      if (this.sal.month && this.sal.year) {
        this.mSalary = {
          allowances: [],
          deductions: [],
          advanceIDs: [],
          loanIDs: [],
          sdIDs: [],
          salary: {
            absent: 0,
            absentDeductionCount: 0,
            shortLeaveDeductionCount: 0,
            lateDeductionCount: 0,
            accountID: 0,
            basicSalary: 0,
            campusID: 0,
            date: this.$store.state.salPaymentDate,
            grossSalary: 0,
            id: 0,
            late: 0,
            leave: 0,
            month: 0,
            monthYear: "",
            netSalary: 0,
            note: null,
            present: 0,
            shortLeave: 0,
            tID: 0,
            totalAllownaces: 0,
            totalDedcutions: 0,
            workingDays: 0,
            year: 0,
            utilizeableLeaves: 0,
            remainingLeaves: 0,
            bonusDaysAmount: 0,
            bonusDays: 0,
            leavesEncashed: 0,
            leavesEncashementAmount: 0,
            securityDeposit: 0,
            loanDeduction: 0,
            advanceDeduction: 0,
            shortLeaveDeductionCount: 0,
            lateDeductionCount: 0,
            departmentID: this.sunmonDepID,
          },
          maxLeavePerMonth: 0,
          remainingLeaves: 0,
          utilizeableLeaves: 0,
        };
        var obj = {
          url: `${this.$store.state.domain}Salary/LoadData?db=${this.$store.state.userData.db}&cID=${this.$store.state.userData.cId}&tID=${this.tID}&month=${this.sal.month}&year=${this.sal.year}`,
          token: this.$store.state.userData.token,
        };
        let res = await this.get(obj);

        if (!Array.isArray(res)) {
          this.mSalary = res;
          if (this.mSalary.salary.departmentID == 0)
            this.mSalary.salary.departmentID = this.sunmonDepID;
        }
        // console.log(Array.isArray(res));
        await this.loadAllowances();
        await this.loadDeductions();
        this.allowIDs = [];
        this.dedIDs = [];
        // this.mSalary.allowances;
        if (this.mSalary.allowances.length > 0) {
          this.mSalary.allowances.forEach((item) => {
            this.allowIDs.push(item.staffAllowanceID);
          });
        } else {
          this.mSalary.allowances = [
            {
              id: 0,
              amount: 0,
              campusID: this.$store.state.userData.cId,
              salaryID: this.mSalary.salary.id,
              tID: this.tID,
              staffAllowanceID: 0,
            },
          ];
        }

        if (this.mSalary.deductions.length > 0) {
          this.mSalary.deductions.forEach((item) => {
            this.dedIDs.push(item.staffDeductionID);
          });
        } else {
          this.mSalary.deductions = [
            {
              id: 0,
              amount: 0,
              campusID: this.$store.state.userData.cId,
              salaryID: this.mSalary.salary.id,
              tID: this.tID,
              staffDeductionID: 0,
            },
          ];
        }

        // console.log(this.mSalary);
        // console.log(this.allowIDs);
      }
    },
    async CalculateSalary() {
      if (
        this.sal.month &&
        this.sal.year &&
        this.checkWDays() == true &&
        this.checkPresent() == true &&
        this.checkAbsent() == true &&
        this.checkAttLeave() == true &&
        this.checkAttLate() == true &&
        this.checkAttHalf() == true &&
        this.checkBonus() == true &&
        this.checkEncashDays() == true
      ) {
        if (this.checkSum() == false) {
          this.$bvToast.toast(
            "The total of present,absent & leave is greater than working days",
            {
              title: "Error!",
              variant: "danger",
              toaster: "b-toaster-top-right",
            }
          );
          return false;
        } else if (this.CheckValues() == false) {
          this.$bvToast.toast(
            "Invalid amount entered on allowances or deductions",
            {
              title: "Error!",
              variant: "danger",
              toaster: "b-toaster-top-right",
            }
          );
          return false;
        } else {
          this.requestCalculate = true;

          this.mSalary.salary.workingDays = parseInt(
            this.mSalary.salary.workingDays
          );
          this.mSalary.salary.present = parseInt(this.mSalary.salary.present);
          this.mSalary.salary.absent = parseInt(this.mSalary.salary.absent);
          this.mSalary.salary.leave = parseInt(this.mSalary.salary.leave);
          this.mSalary.salary.late = parseInt(this.mSalary.salary.late);
          this.mSalary.salary.shortLeave = parseInt(
            this.mSalary.salary.shortLeave
          );
          this.mSalary.salary.bonusDays = parseInt(
            this.mSalary.salary.bonusDays
          );
          this.mSalary.salary.leavesEncashed = parseInt(
            this.mSalary.salary.leavesEncashed
          );

          let res = await this.postObj({
            url:
              this.$store.state.domain +
              "Salary/CalculateSalary?db=" +
              this.$store.state.userData.db,
            body: this.mSalary,
            attendance: true,
            context: this,
            token: this.$store.state.userData.token,
          });
          if (res != false) {
            this.mSalary = res;
          }
          await this.loadAllowances();
          await this.loadDeductions();
          this.allowIDs = [];
          this.dedIDs = [];
          // this.mSalary.allowances;
          if (this.mSalary.allowances.length > 0) {
            this.mSalary.allowances.forEach((item) => {
              this.allowIDs.push(item.staffAllowanceID);
            });
          } else {
            this.mSalary.allowances = [
              {
                id: 0,
                amount: 0,
                campusID: this.$store.state.userData.cId,
                salaryID: this.mSalary.salary.id,
                tID: this.tID,
                staffAllowanceID: 0,
              },
            ];
          }

          if (this.mSalary.deductions.length > 0) {
            this.mSalary.deductions.forEach((item) => {
              this.dedIDs.push(item.staffDeductionID);
            });
          } else {
            this.mSalary.deductions = [
              {
                id: 0,
                amount: 0,
                campusID: this.$store.state.userData.cId,
                salaryID: this.mSalary.salary.id,
                tID: this.tID,
                staffDeductionID: 0,
              },
            ];
          }

          this.requestCalculate = false;
          return true;
        }
      } else return false;
    },
    async SaveSalary(print) {
      this.request = true;
      let res = await this.CalculateSalary();
      if (res) {
        this.mSalary.salary.workingDays = parseInt(
          this.mSalary.salary.workingDays
        );
        this.mSalary.salary.present = parseInt(this.mSalary.salary.present);
        this.mSalary.salary.absent = parseInt(this.mSalary.salary.absent);
        this.mSalary.salary.leave = parseInt(this.mSalary.salary.leave);
        this.mSalary.salary.late = parseInt(this.mSalary.salary.late);
        this.mSalary.salary.shortLeave = parseInt(
          this.mSalary.salary.shortLeave
        );
        this.mSalary.salary.bonusDays = parseInt(this.mSalary.salary.bonusDays);
        this.mSalary.salary.leavesEncashed = parseInt(
          this.mSalary.salary.leavesEncashed
        );
        this.mSalary.salary.date = this.currentDate;

        var status = await this.post({
          url:
            this.$store.state.domain +
            "Salary/Save?db=" +
            this.$store.state.userData.db,
          body: this.mSalary,
          context: this,
          savingMarks: true,
          message: "Salary created!",
          token: this.$store.state.userData.token,
        });
        // console.log(status);
        if (status && print) {
          let url = `https://${this.reportDomain}.myskool.app/Account/SalarySlip?dbb=${this.$store.state.userData.db}&cID=${this.$store.state.userData.cId}&salaryID=${status.salary.id}`;
          window.open(url, "_blank");
          // window.open("https://cdn.cloudious.net/salarySlip.jpeg", "_blank");
        }
        this.request = false;
        if (status) this.visibility = false;
      }
      this.request = false;
    },
    async openHistory() {
      // this.$bvModal.show("modal-history");

      this.isLoan = false;
      this.isDeposit = false;
      this.showAtt = false;
      this.isHistory = true;
      this.otherLoading = true;
      // this.history.month = null;
      // this.history.year = null;

      this.historyData = await this.get({
        url:
          this.$store.state.domain +
          "Salary/LoadHistory?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&tID=0&dptID=" +
          this.currentDep,
        token: this.$store.state.userData.token,
      });
      //      console.log(this.historyData);
      this.allHistory = this.historyData;
      this.filterHistory();
      this.otherLoading = false;
    },
    filterHistory() {
      if (this.history.month && this.history.year) {
        this.historyData = this.allHistory.filter(
          (el) =>
            el.us.month == this.history.month && el.us.year == this.history.year
        );
      } else if (this.history.month || this.history.year) {
        if (this.history.month) {
          this.historyData = this.allHistory.filter(
            (el) => el.us.month == this.history.month
          );
        }
        if (this.history.year) {
          this.historyData = this.allHistory.filter(
            (el) => el.us.year == this.history.year
          );
        }
      } else {
        this.historyData = this.allHistory;
      }
    },
    checkHMonth() {
      var elem = this.$refs["his_month"];
      if (this.history.month == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkHYear() {
      var elem = this.$refs["his_year"];
      if (!this.history.year) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async showHistory() {
      if (this.checkHMonth() == true && this.checkHYear() == true) {
        this.isHistory = true;
        this.dataLoading = true;
        this.$bvModal.hide("modal-history");
        // this.items = await this.get({
        //   url:
        //     this.$store.state.domain +
        //     "staff/filter?db=" +
        //     this.$store.state.userData.db +
        //     "&cId=" +
        //     this.$store.state.userData.cId +
        //     "&dptID=0&status=present",
        //   token: this.$store.state.userData.token,
        // });
        this.dataLoading = false;
      }
    },
    openPayroll(text) {
      if (text == "msg") this.showMsg = true;
      else this.showMsg = false;
      this.$bvModal.show("modal-payroll");
    },
    setDeps() {
      if (this.payroll.depIDs.at(-1) == 0) {
        this.payroll.depIDs = [0];
      } else {
        this.payroll.depIDs = this.payroll.depIDs.filter((el) => el != 0);
      }
    },
    checkPDeps() {
      var elem = this.$refs["pay_depart"];
      if (this.payroll.depIDs.length == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkPMonth() {
      var elem = this.$refs["pay_month"];
      if (this.payroll.month == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkPYear() {
      var elem = this.$refs["pay_year"];
      if (!this.payroll.year) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkPDate() {
      if (this.payroll.report == "bank") {
        var elem = this.$refs["pay_date"];
        if (!this.payroll.date) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    checkPCheque() {
      if (this.payroll.report == "bank") {
        var elem = this.$refs["pay_cheq"];
        if (this.payroll.cheque == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    showPayroll() {
      if (
        // this.checkPDeps() == true &&
        this.checkPMonth() == true &&
        this.checkPYear() == true &&
        this.checkPDate() == true &&
        this.checkPCheque() == true
      ) {
        // console.log(this.payroll);
        if (this.payroll.report == "bank") {
          let url = `https://${this.reportDomain}.myskool.app/Account/BankList?dbb=${this.$store.state.userData.db}&cID=${this.$store.state.userData.cId}&month=${this.payroll.month}&year=${this.payroll.year}&dptID=${this.payroll.depIDs}&date=${this.payroll.date}&cheque=${this.payroll.cheque}`;
          window.open(url, "_blank");
        } else if (this.payroll.report == "cash") {
          let url = `https://${this.reportDomain}.myskool.app/Account/PayrollCash?dbb=${this.$store.state.userData.db}&cID=${this.$store.state.userData.cId}&month=${this.payroll.month}&year=${this.payroll.year}&dptID=${this.payroll.depIDs}&type=${this.payroll.report}`;
          window.open(url, "_blank");
        } else {
          let url = `https://${this.reportDomain}.myskool.app/Account/Payroll?dbb=${this.$store.state.userData.db}&cID=${this.$store.state.userData.cId}&month=${this.payroll.month}&year=${this.payroll.year}&dptID=${this.payroll.depIDs}&type=${this.payroll.report}`;
          window.open(url, "_blank");
        }
      }
    },
    exportPayroll() {
      if (
        // this.checkPDeps() == true &&
        this.checkPMonth() == true &&
        this.checkPYear() == true &&
        this.checkPDate() == true &&
        this.checkPCheque() == true
      ) {
        // console.log(this.payroll);
        this.exporting = true;
        let url = "";
        if (this.payroll.report == "bank") {
          url = `https://${this.reportDomain}.myskool.app/Account/BankListExport?dbb=${this.$store.state.userData.db}&cID=${this.$store.state.userData.cId}&month=${this.payroll.month}&year=${this.payroll.year}&dptID=${this.payroll.depIDs}&date=${this.payroll.date}&cheque=${this.payroll.cheque}`;
        } else if (this.payroll.report == "cash") {
          url = `https://${this.reportDomain}.myskool.app/Account/PayrollCashExport?dbb=${this.$store.state.userData.db}&cID=${this.$store.state.userData.cId}&month=${this.payroll.month}&year=${this.payroll.year}&dptID=${this.payroll.depIDs}&type=${this.payroll.report}`;
        } else {
          url = `https://${this.reportDomain}.myskool.app/Account/PayrollExport?dbb=${this.$store.state.userData.db}&cID=${this.$store.state.userData.cId}&month=${this.payroll.month}&year=${this.payroll.year}&dptID=${this.payroll.depIDs}&type=${this.payroll.report}`;
        }
        axios
          .get(url)
          .then((response) => {
            // console.log(response.data.data);
            window.open(response.data.data, "_blank");
          })
          .catch((error) => {
            // Handle errors here
            console.error("There was an error!", error);
          })
          .finally(() => {
            this.exporting = false;
          });
      }
    },
    async send() {
      if (
        // this.checkPDeps() == true &&
        this.checkPMonth() == true &&
        this.checkPYear() == true
      ) {
        try {
          // console.log(this.payroll);
          this.sending = true;
          await this.postObj({
            url:
              this.$store.state.domain +
              "Salary/SendPaySlip?db=" +
              this.$store.state.userData.db +
              "&salaryID=0&dID=" +
              this.payroll.depIDs +
              "&month=" +
              this.payroll.month +
              "&year=" +
              this.payroll.year +
              "&cID=" +
              this.$store.state.userData.cId +
              "&userID=" +
              this.$store.state.userData.userID,
            message: "Pay Slip sent on WhatsApp!",
            context: this,
            token: this.$store.state.userData.token,
          });
        } catch (error) {
        } finally {
          this.sending = false;
        }
      }
    },
    async sendSingle(id) {
      try {
        this.currentID = id;
        this.singleSending = true;
        await this.postObj({
          url:
            this.$store.state.domain +
            "Salary/SendPaySlip?db=" +
            this.$store.state.userData.db +
            "&salaryID=" +
            id +
            "&dID=0&month=0&year=0&cID=" +
            this.$store.state.userData.cId +
            "&userID=" +
            this.$store.state.userData.userID,
          message: "Pay Slip sent on WhatsApp!",
          context: this,
          token: this.$store.state.userData.token,
        });
      } catch (error) {
      } finally {
        this.singleSending = false;
      }
    },
    printSlip(id) {
      let url = `https://${this.reportDomain}.myskool.app/Account/SalarySlip?dbb=${this.$store.state.userData.db}&cID=${this.$store.state.userData.cId}&salaryID=${id}`;
      window.open(url, "_blank");
    },
    async DeleteSal(id) {
      console.log(id);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "Salary/DeleteSelected?db=" +
            this.$store.state.userData.db +
            "&salaryID=" +
            id,
          body: null,
          message: "Salary deleted successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.openHistory();
      }
    },

    async openSetting() {
      await this.loadSettings();

      this.leavesObj = this.settingData.find(
        (el) => el.categoy == "Leave Allowed"
      );
      // console.log(this.leavesObj);
      this.lateObj = this.settingData.find(
        (el) => el.categoy == "Late Coming Scale"
      );
      // console.log(this.lateObj);
      this.shortObj = this.settingData.find(
        (el) => el.categoy == "Short Leave Scale"
      );
      // console.log(this.shortObj);
      this.attObj = this.settingData.find(
        (el) => el.categoy == "Attendance Allowance"
      );
      // console.log(this.attObj);
      this.encashObj = this.settingData.find(
        (el) => el.categoy == "Leave Encashment"
      );
      // console.log(this.encashObj);
      this.maxObj = this.settingData.find(
        (el) => el.categoy == "Max Leave per Month"
      );
      if (!this.maxObj) {
        this.maxObj = {
          id: 0,
          tID: 0,
          categoy: "Max Leave per Month",
          enabled: this.leavesObj.option == "Yearly",
          number: 0,
          option: "",
          campusID: this.$store.state.userData.cId,
        };
      }
      // console.log(this.maxObj);
      this.minuteObj = this.settingData.find(
        (el) => el.categoy == "Late Coming Deduction per Minute"
      );
      if (!this.minuteObj) {
        this.minuteObj = {
          id: 0,
          tID: 0,
          categoy: "Late Coming Deduction per Minute",
          enabled: false,
          number: 0,
          option: "",
          campusID: this.$store.state.userData.cId,
        };
      }
      // console.log(this.minuteObj);
      this.earlyObj = this.settingData.find(
        (el) => el.categoy == "Early Departure"
      );
      if (!this.earlyObj) {
        this.earlyObj = {
          id: 0,
          tID: 0,
          categoy: "Early Departure",
          enabled: false,
          number: 0,
          option: "No",
          campusID: this.$store.state.userData.cId,
        };
      }
      // console.log(this.earlyObj);
      this.sandwichObj = this.settingData.find(
        (el) => el.categoy == "Sandwich Policy"
      );
      if (!this.sandwichObj) {
        this.sandwichObj = {
          id: 0,
          tID: 0,
          categoy: "Sandwich Policy",
          enabled: false,
          number: 0,
          option: "",
          campusID: this.$store.state.userData.cId,
        };
      }
      // console.log(this.sandwichObj);

      this.showSettings = true;

      this.showHalfHint = true;
      this.showLateHint = true;
      this.showEarly = true;
    },
    setMaxLeaves() {
      if (this.leavesObj.option == "Yearly") {
        this.maxObj.enabled = true;
      } else this.maxObj.enabled = false;
      // console.log(this.maxObj);
    },

    setDate() {
      let d = this.rangeDate.split(" to ");
      this.settObj.dateFrom = d[0];
      this.settObj.dateTo = d[1];
      // console.log(this.rangeDate);
      // console.log(this.settObj.dateFrom, this.settObj.dateTo);
    },

    setHalfHint() {
      var elem = this.$refs["shortScale"];
      var re = /^[0-9]+$/;

      if (this.shortObj.number != "" && this.shortObj.number != 0) {
        if (re.test(this.shortObj.number) && this.shortObj.number > 0) {
          this.showHalfHint = true;
          return (elem.state = true);
        } else {
          this.showHalfHint = false;
          return (elem.state = false);
        }
      } else {
        this.showHalfHint = false;
        return (elem.state = false);
      }
    },
    setLateHint() {
      var elem = this.$refs["lateScale"];
      if (this.lateObj.number != "" && this.lateObj.number != 0) {
        var re = /^[0-9]+$/;

        if (re.test(this.lateObj.number) && this.lateObj.number > 0) {
          this.showLateHint = true;
          return (elem.state = true);
        } else {
          this.showLateHint = false;
          return (elem.state = false);
        }
      } else {
        this.showLateHint = false;
        return (elem.state = false);
      }
    },
    checkAllowed() {
      if (this.leavesObj.enabled) {
        var elem = this.$refs["all_cat"];
        if (this.leavesObj.option == "") {
          return (elem.state = false);
        } else {
          elem.state = true;
          if (this.checkLeaves() == true) {
            if (this.leavesObj.option == "Yearly") return this.checkMaxLeave();
          } else return false;
        }
      } else return true;
    },
    checkLeaves() {
      var elem = this.$refs["leave_allowed"];
      var re = /^[0-9]+$/;
      let x = parseInt(this.leavesObj.number);
      if (!re.test(this.leavesObj.number) || isNaN(x) || x < 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkMaxLeave() {
      var elem = this.$refs["max_allowed"];
      var re = /^[0-9]+$/;
      let x = parseInt(this.maxObj.number);
      if (
        !re.test(this.maxObj.number) ||
        isNaN(x) ||
        x <= 0 ||
        x > this.leavesObj.number
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkYear() {
      var elem = this.$refs["yearDate"];
      if (!this.rangeDate || !this.settObj.dateFrom || !this.settObj.dateTo) {
        return (elem.state = false);
      } else return (elem.state = true);
    },
    checkLate() {
      if (this.lateObj.enabled) {
        return this.setLateHint();
      } else return true;
    },
    checkShort() {
      if (this.shortObj.enabled) {
        return this.setHalfHint();
      } else return true;
    },
    checkFields() {
      setTimeout(() => {
        this.checkAtt();
      }, 200);
    },
    checkAtt() {
      if (this.attObj.enabled) {
        if (this.attObj.option == "Fixed amount") return this.checkAmount();
        else if (this.attObj.option == "Percentage") return this.checkPerc();
        // else return this.checkDays();
        else return true;
      } else return true;
    },
    checkAmount() {
      var elem = this.$refs["amount"];
      var re = /^[0-9]+$/;
      let x = parseInt(this.attObj.number);
      if (!re.test(this.attObj.number) || isNaN(x) || x < 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkPerc() {
      var elem = this.$refs["percentage"];
      // let x = parseFloat(this.attObj.number);
      var re = /^[0-9]+$/;
      let x = parseInt(this.attObj.number);
      if (!re.test(this.attObj.number) || isNaN(x) || x < 0 || x > 100) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkDays() {
      var elem = this.$refs["days"];
      var re = /^[0-9]+$/;
      let x = parseInt(this.attObj.number);
      if (!re.test(this.attObj.number) || isNaN(x) || x < 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkMax() {
      if (this.encashObj.enabled) {
        var elem = this.$refs["maxEn"];
        var re = /^[0-9]+$/;
        let x = parseInt(this.encashObj.number);
        if (
          !re.test(this.encashObj.number) ||
          isNaN(x) ||
          x < 0 ||
          x > this.leavesObj.number
        ) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    checkPerMin() {
      if (this.minuteObj.enabled) {
        var elem = this.$refs["lateAmount"];
        var re = /^[0-9]+$/;
        let x = parseInt(this.minuteObj.number);
        if (!re.test(this.minuteObj.number) || isNaN(x) || x < 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      }
      return true;
    },
    setEarlyHint() {
      var elem = this.$refs["earlyScale"];
      if (this.earlyObj.number != "" && this.earlyObj.number != 0) {
        var re = /^[0-9]+$/;

        if (re.test(this.earlyObj.number) && this.earlyObj.number > 0) {
          this.showEarly = true;
          return (elem.state = true);
        } else {
          this.showEarly = false;
          return (elem.state = false);
        }
      } else {
        this.showEarly = false;
        return (elem.state = false);
      }
    },
    checkEarly() {
      if (this.earlyObj.enabled) {
        return this.setEarlyHint();
      } else return true;
    },
    async saveSettings() {
      // console.log(this.settObj);
      if (
        this.checkAllowed() == false ||
        this.checkLate() == false ||
        this.checkShort() == false ||
        this.checkAtt() == false ||
        this.checkMax() == false ||
        this.checkPerMin() == false ||
        this.checkEarly() == false
      ) {
        this.$bvToast.toast("Enter the details correctly!", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
        });
      } else {
        this.saving = true;

        if (!this.leavesObj.number) this.leavesObj.number = 0;
        else this.leavesObj.number = parseInt(this.leavesObj.number);
        if (!this.lateObj.number) this.lateObj.number = 0;
        else this.lateObj.number = parseInt(this.lateObj.number);
        if (!this.shortObj.number) this.shortObj.number = 0;
        else this.shortObj.number = parseInt(this.shortObj.number);
        if (!this.attObj.number) this.attObj.number = 0;
        else this.attObj.number = parseInt(this.attObj.number);
        if (!this.encashObj.number) this.encashObj.number = 0;
        else this.encashObj.number = parseInt(this.encashObj.number);
        if (!this.maxObj.number) this.maxObj.number = 0;
        else this.maxObj.number = parseInt(this.maxObj.number);
        if (!this.minuteObj.number) this.minuteObj.number = 0;
        else this.minuteObj.number = parseInt(this.minuteObj.number);
        if (!this.earlyObj.number) this.earlyObj.number = 0;
        else this.earlyObj.number = parseInt(this.earlyObj.number);

        let saveObj = [
          this.leavesObj,
          this.lateObj,
          this.shortObj,
          this.attObj,
          this.encashObj,
          this.maxObj,
          this.minuteObj,
          this.earlyObj,
          this.sandwichObj,
        ];
        // console.log(saveObj);

        var status = await this.post({
          url:
            this.$store.state.domain +
            "SalaryPolicy/SaveData?db=" +
            this.$store.state.userData.db,
          body: saveObj,
          message: "Salary Settings saved successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });

        if (status) {
          this.loadSettings();
          this.showSettings = false;
        }
        this.saving = false;
      }
    },

    openDetails() {
      // this.AddingInfo = [];
      // this.AddingInfo.push(this.salaryObj);
      this.showDetails = true;
    },

    changeAllow() {
      this.allowIDs = this.mSalary.allowances.reduce((acc, el) => {
        acc.push(el.staffAllowanceID);
        return acc;
      }, []);
      // console.log(this.allowIDs);
    },
    changeDed() {
      this.dedIDs = this.mSalary.deductions.reduce((acc, el) => {
        acc.push(el.staffDeductionID);
        return acc;
      }, []);
      // console.log(this.dedIDs);
    },
    AddDetail(item) {
      this.mSalary.allowances.push({
        id: 0,
        amount: 0,
        campusID: this.mSalary.salary.campusID,
        salaryID: this.mSalary.salary.id,
        tID: this.tID,
        staffAllowanceID: 0,
      });
    },
    async removeDetail(item, ind) {
      //console.log(ind, item);
      if (item.id == 0) {
        this.mSalary.allowances.splice(ind, 1);
        this.changeAllow();

        let curr = this.allowances.find((el) => el.id == item.staffAllowanceID);
        if (curr.title == "Deposit Return") this.mSalary.sdReturnIDs = [];
      } else {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "salaryAllowances/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          grading: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.mSalary.allowances.splice(ind, 1);
          this.changeAllow();

          let curr = this.allowances.find(
            (el) => el.id == item.staffAllowanceID
          );
          if (curr.title == "Deposit Return") this.mSalary.sdReturnIDs = [];
        }
      }
    },
    AddDeduct(item) {
      this.mSalary.deductions.push({
        id: 0,
        amount: 0,
        campusID: this.mSalary.salary.campusID,
        salaryID: this.mSalary.salary.id,
        tID: this.tID,
        staffDeductionID: 0,
      });
    },
    async removeDeduct(item, ind) {
      if (item.id == 0) {
        this.mSalary.deductions.splice(ind, 1);
        this.changeDed();
      } else {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "salaryDeductions/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          grading: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.mSalary.deductions.splice(ind, 1);
          this.changeDed();
        }
        // console.log(this.deductInfo);
      }
    },

    async openReturns() {
      if (
        this.sal.month &&
        this.sal.year &&
        this.checkWDays() == true &&
        this.checkPresent() == true &&
        this.checkAbsent() == true &&
        this.checkAttLeave() == true &&
        this.checkAttLate() == true &&
        this.checkAttHalf() == true &&
        this.checkBonus() == true &&
        this.checkEncashDays() == true
      ) {
        if (this.checkSum() == false) {
          this.$bvToast.toast(
            "The total of present,absent & leave is greater than working days",
            {
              title: "Error!",
              variant: "danger",
              toaster: "b-toaster-top-right",
            }
          );
        } else if (this.CheckValues() == false) {
          this.$bvToast.toast(
            "Invalid amount entered on allowances or deductions",
            {
              title: "Error!",
              variant: "danger",
              toaster: "b-toaster-top-right",
            }
          );
        } else {
          this.returnLoading = true;

          this.mSalary.salary.workingDays = parseInt(
            this.mSalary.salary.workingDays
          );
          this.mSalary.salary.present = parseInt(this.mSalary.salary.present);
          this.mSalary.salary.absent = parseInt(this.mSalary.salary.absent);
          this.mSalary.salary.leave = parseInt(this.mSalary.salary.leave);
          this.mSalary.salary.late = parseInt(this.mSalary.salary.late);
          this.mSalary.salary.shortLeave = parseInt(
            this.mSalary.salary.shortLeave
          );
          this.mSalary.salary.bonusDays = parseInt(
            this.mSalary.salary.bonusDays
          );
          this.mSalary.salary.leavesEncashed = parseInt(
            this.mSalary.salary.leavesEncashed
          );

          // console.log(this.mSalary);
          this.mSalary = await this.postObj({
            url:
              this.$store.state.domain +
              "Salary/ReturnDeposit?db=" +
              this.$store.state.userData.db,
            body: this.mSalary,
            attendance: true,
            context: this,
            token: this.$store.state.userData.token,
          });
          this.returnLoading = false;

          this.CalculateSalary();
        }
      }

      // this.loadAllowances();
      // this.loadDeductions();
    },
    async saveReturns() {
      if (this.returnIDs.length > 0) {
        this.returnLoading = true;
        console.log(this.returnIDs);
      } else {
        var elem = this.$refs["ret_deds"];
        elem.state = false;
      }
    },

    async singleHistory(id) {
      this.isHistory = true;
      this.otherLoading = true;
      // this.history.month = null;
      // this.history.year = null;

      this.historyData = await this.get({
        url:
          this.$store.state.domain +
          "Salary/LoadHistory?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&tID=" +
          id +
          "&dptID=0",
        token: this.$store.state.userData.token,
      });

      // console.log(this.historyData);
      this.allHistory = this.historyData;
      this.filterHistory();
      this.otherLoading = false;
    },
    async showOthers(id, text) {
      if (text == "Advance / Loan") this.isLoan = true;
      else this.isDeposit = true;

      this.otherLoading = true;
      var res = await this.get({
        url:
          this.$store.state.domain +
          "staff/LoadSelectedStaff?db=" +
          this.$store.state.userData.db +
          "&id=" +
          id,
        context: this,
        token: this.$store.state.userData.token,
      });

      this.currentStaff = res;
      this.loanData = res.advanceAndLoans;
      this.loanDetails = res.loanDetails;
      this.depositDetails = res.securityDepositGrid;
      this.depoSaveList = res.securityDeposit;

      this.otherLoading = false;
    },
    async Edit(item, depID) {
      this.sunmonDepID = depID;
      if (this.sunObj && this.sunmonDepID != 9) {
        this.sunmonValue = true;
      } else this.sunmonValue = false;
      // console.log("THis is", this.sunmonDepID, this.sunmonValue, item, depID);

      this.tID = item.id;
      this.mSalary = {
        allowances: [],
        deductions: [],
        advanceIDs: [],
        loanIDs: [],
        sdIDs: [],
        salary: {
          absent: 0,
          absentDeductionCount: 0,
          shortLeaveDeductionCount: 0,
          lateDeductionCount: 0,
          accountID: 0,
          basicSalary: 0,
          campusID: 0,
          date: this.$store.state.salPaymentDate,
          grossSalary: 0,
          id: 0,
          late: 0,
          leave: 0,
          month: 0,
          monthYear: "",
          netSalary: 0,
          note: null,
          present: 0,
          shortLeave: 0,
          tID: 0,
          totalAllownaces: 0,
          totalDedcutions: 0,
          workingDays: 0,
          year: 0,
          utilizeableLeaves: 0,
          remainingLeaves: 0,
          bonusDaysAmount: 0,
          bonusDays: 0,
          leavesEncashed: 0,
          leavesEncashementAmount: 0,
          securityDeposit: 0,
          loanDeduction: 0,
          advanceDeduction: 0,
          shortLeaveDeductionCount: 0,
          lateDeductionCount: 0,
          departmentID: depID,
        },
        maxLeavePerMonth: 0,
        remainingLeaves: 0,
        utilizeableLeaves: 0,
      };
      this.sal.month = this.$store.state.monthNo;
      this.sal.year = this.$store.state.salYear;
      this.LoadSalary();

      this.visibility = true;

      this.mSalary.allowances = [
        {
          id: 0,
          amount: 0,
          campusID: this.$store.state.userData.cId,
          salaryID: this.mSalary.salary.id,
          tID: this.tID,
          staffAllowanceID: 0,
        },
      ];

      this.mSalary.deductions = [
        {
          id: 0,
          amount: 0,
          campusID: this.$store.state.userData.cId,
          salaryID: this.mSalary.salary.id,
          tID: this.tID,
          staffDeductionID: 0,
        },
      ];
      // this.allowances.forEach((el) => {
      //   this.mSalary.allowances.push({
      //     id: 0,
      //     amount: !el.amount ? 0 : el.amount,
      //     campusID: this.$store.state.userData.cId,
      //     salaryID: this.mSalary.salary.id,
      //     tID: this.tID,
      //     staffAllowanceID: el.id,
      //   });
      // });

      // this.deductions.forEach((el) => {
      //   this.mSalary.deductions.push({
      //     id: 0,
      //     amount: !el.amount ? 0 : el.amount,
      //     campusID: this.$store.state.userData.cId,
      //     salaryID: this.mSalary.salary.id,
      //     tID: this.tID,
      //     staffDeductionID: el.id,
      //   });
      // });

      // this.sidebarTitle = "Employee's Salary";
      this.sidebarTitle = `${item.name} - ${item.designation}`;
      this.sidebarButton = "Save";
    },
    async EditSalary(item, depID) {
      this.sunmonDepID = depID;
      if (this.sunObj && this.sunmonDepID != 9) {
        this.sunmonValue = true;
      } else this.sunmonValue = false;
      this.tID = item.us.tID;
      this.mSalary = {
        allowances: [],
        deductions: [],
        advanceIDs: [],
        loanIDs: [],
        sdIDs: [],
        salary: {
          absent: 0,
          absentDeductionCount: 0,
          shortLeaveDeductionCount: 0,
          lateDeductionCount: 0,
          accountID: 0,
          basicSalary: 0,
          campusID: 0,
          date: this.$store.state.salPaymentDate,
          grossSalary: 0,
          id: 0,
          late: 0,
          leave: 0,
          month: 0,
          monthYear: "",
          netSalary: 0,
          note: null,
          present: 0,
          shortLeave: 0,
          tID: 0,
          totalAllownaces: 0,
          totalDedcutions: 0,
          workingDays: 0,
          year: 0,
          utilizeableLeaves: 0,
          remainingLeaves: 0,
          bonusDaysAmount: 0,
          bonusDays: 0,
          leavesEncashed: 0,
          leavesEncashementAmount: 0,
          securityDeposit: 0,
          loanDeduction: 0,
          advanceDeduction: 0,
          shortLeaveDeductionCount: 0,
          lateDeductionCount: 0,
          departmentID: depID,
        },
        maxLeavePerMonth: 0,
        remainingLeaves: 0,
        utilizeableLeaves: 0,
      };
      this.mSalary.salary = item.us;
      this.sal.month = item.us.month;
      this.sal.year = item.us.year;
      this.LoadSalary();

      this.visibility = true;

      this.mSalary.allowances = [
        {
          id: 0,
          amount: 0,
          campusID: this.$store.state.userData.cId,
          salaryID: this.mSalary.salary.id,
          tID: this.tID,
          staffAllowanceID: 0,
        },
      ];

      this.mSalary.deductions = [
        {
          id: 0,
          amount: 0,
          campusID: this.$store.state.userData.cId,
          salaryID: this.mSalary.salary.id,
          tID: this.tID,
          staffDeductionID: 0,
        },
      ];
      this.sidebarTitle = `${item.st.name} - ${item.st.designation}`;
      this.sidebarButton = "Save";
      this.LoadData();
    },
    setTillDate(item) {
      let d = new Date(item.dateFrom);
      item.dateTo = d.setFullYear(d.getFullYear() + 1);
      console.log(this.policyObj);
    },

    AddPolicy(item) {
      if (item.type != "" && item.days !== "") {
        if (item.type == "yearly" && item.dateFrom) {
          let d = new Date(item.dateTo);

          let newfrom = new Date(d);
          newfrom.setDate(d.getDate() + 1);

          let newto = new Date(newfrom);
          newto.setFullYear(newfrom.getFullYear() + 1);

          this.policyObj.push({
            id: 0,
            tID: this.myObj.id,
            type: "yearly",
            month: "",
            dateFrom: newfrom.toISOString().split("T")[0],
            dateTo: newto.toISOString().split("T")[0],
            days: 0,
            campusID: this.$store.state.userData.cId,
          });
        } else if (item.type == "monthly" && item.month != "") {
          let currentDate = new Date(`1 ${item.month} 2000`);
          currentDate.setMonth(currentDate.getMonth() + 1);
          let nextMonthString = currentDate.toLocaleString("default", {
            month: "long",
          });

          this.policyObj.push({
            id: 0,
            tID: this.myObj.id,
            type: "monthly",
            month: nextMonthString,
            dateFrom: null,
            dateTo: null,
            days: 0,
            campusID: this.$store.state.userData.cId,
          });
        }
      }
      console.log(this.policyObj);
    },
    removePolicy(ind) {
      this.policyObj.splice(ind, 1);
    },
    checkBonus() {
      var elem = this.$refs["bDays"];
      var re = /^[0-9]+$/;
      let x = parseInt(this.mSalary.salary.bonusDays);
      if (!re.test(this.mSalary.salary.bonusDays) || isNaN(x) || x < 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkEncashDays() {
      var elem = this.$refs["bDays"];
      var re = /^[0-9]+$/;
      let x = parseInt(this.mSalary.salary.leavesEncashed);
      if (!re.test(this.mSalary.salary.leavesEncashed) || isNaN(x) || x < 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkWDays() {
      var elem = this.$refs["wDays"];
      var re = /^[0-9]+$/;
      let x = parseInt(this.mSalary.salary.workingDays);
      if (!re.test(this.mSalary.salary.workingDays) || isNaN(x) || x <= 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkPresent() {
      var elem = this.$refs["present"];
      var re = /^[0-9]+$/;
      let x = parseInt(this.mSalary.salary.present);
      if (!re.test(this.mSalary.salary.present) || isNaN(x) || x < 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkAbsent() {
      var elem = this.$refs["absent"];
      var re = /^[0-9]+$/;
      let x = parseInt(this.mSalary.salary.absent);
      if (!re.test(this.mSalary.salary.absent) || isNaN(x) || x < 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkAttLeave() {
      var elem = this.$refs["leave"];
      var re = /^[0-9]+$/;
      let x = parseInt(this.mSalary.salary.leave);
      if (!re.test(this.mSalary.salary.leave) || isNaN(x) || x < 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkAttLate() {
      var elem = this.$refs["late"];
      var re = /^[0-9]+$/;
      let x = parseInt(this.mSalary.salary.late);
      if (!re.test(this.mSalary.salary.late) || isNaN(x) || x < 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkAttHalf() {
      var elem = this.$refs["half"];
      var re = /^[0-9]+$/;
      let x = parseInt(this.mSalary.salary.shortLeave);
      if (!re.test(this.mSalary.salary.shortLeave) || isNaN(x) || x < 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkSum() {
      let total =
        parseInt(this.mSalary.salary.present) +
        parseInt(this.mSalary.salary.absent) +
        parseInt(this.mSalary.salary.leave);
      // console.log(
      //   total,
      //   total > this.mSalary.salary.workingDays,
      //   total < this.mSalary.salary.workingDays
      // );
      return total <= this.mSalary.salary.workingDays;
    },
    CheckValues() {
      let state = true;
      this.mSalary.allowances = this.mSalary.allowances.filter(
        (el) => el.staffAllowanceID != 0
      );
      this.mSalary.allowances.forEach((el) => {
        el.amount = parseInt(el.amount);
        if (isNaN(el.amount) || el.amount < 0) {
          state = false;
        }
      });

      this.mSalary.deductions = this.mSalary.deductions.filter(
        (el) => el.staffDeductionID != 0
      );
      this.mSalary.deductions.forEach((el) => {
        el.amount = parseInt(el.amount);
        if (isNaN(el.amount) || el.amount < 0) {
          state = false;
        }
      });

      return state;
    },

    hideSideBar() {
      this.visibility = false;
    },
    async LoadAccounts() {
      var obj2 = {
        url:
          this.$store.state.domain +
          "CollectionAccounts?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.accTypes = await this.get(obj2);
      // console.log("acc", this.accTypes);
    },
    async loadAllowances() {
      var obj = {
        url:
          this.$store.state.domain +
          "Allowances?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.allowances = await this.get(obj);
    },
    async loadDeductions() {
      var obj = {
        url:
          this.$store.state.domain +
          "Deductions?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.deductions = await this.get(obj);
      this.deductions = this.deductions.filter((el) => !el.isHidden);
    },
    async LoadData() {
      this.dataLoading = true;
      this.isHistory = false;
      // this.items = await this.get({
      //   url:
      //     this.$store.state.domain +
      //     "staff/filter?db=" +
      //     this.$store.state.userData.db +
      //     "&cId=" +
      //     this.$store.state.userData.cId +
      //     "&dptID=" +
      //     this.currentDep +
      //     "&status=present",
      //   token: this.$store.state.userData.token,
      // });
      let myitem = await this.get({
        url:
          this.$store.state.domain +
          "staff/LoadDataPagination?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dptID=" +
          this.currentDep +
          "&status=present&page=" +
          this.currentPage,
        token: this.$store.state.userData.token,
        dashboard: true,
      });
      this.items = myitem.data;
      this.totalCount = myitem.count;
      this.dataLoading = false;
      // console.log("items", this.items);
    },
    async loadSearch() {
      if (this.searchQuery.trim() == "") {
        this.currentPage = 1;
        this.LoadData();
      } else {
        this.dataLoading = true;
        let myitem = await this.get({
          url:
            this.$store.state.domain +
            "staff/Search?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&keyword=" +
            this.searchQuery +
            "&status=present",
          token: this.$store.state.userData.token,
        });
        this.items = myitem;
        this.totalCount = 0;
        this.currentPage = 1;
        this.dataLoading = false;
      }
    },
    async loadDep() {
      this.departments = await this.get({
        url:
          this.$store.state.domain +
          "StaffDepartments?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      });
    },
    async loadOther() {
      var obj = {
        url:
          this.$store.state.domain +
          "Settings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      // console.log(res);
      this.sunObj = res.find((el) => el.key == "sunmon");
      // console.log(this.sunObj);
      this.entryObj = res.find(
        (el) => el.key.replace(/\r\n/g, "") == "double_entry"
      );
      // console.log(this.entryObj);
      this.doubleObj = res.find((el) => el.key == "double-absent");
      // console.log(this.doubleObj);
    },

    async loadSettings() {
      this.settLoading = true;

      this.settingData = await this.get({
        url:
          this.$store.state.domain +
          "SalaryPolicy/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&tID=0",
        token: this.$store.state.userData.token,
      });
      // console.log(this.settingData);

      this.settLoading = false;
    },

    async Add() {
      console.log(
        this.myObj,
        this.sal,
        this.attCount,
        this.deductInfo,
        this.AddingInfo
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/apps/calendar.scss";
</style>

<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.card .card {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
  margin-bottom: 0.5rem;
}
::v-deep.vs__clear {
  margin-right: 1px !important;
}
::v-deep.fc-daygrid-dot-event:hover {
  background: none !important;
}
</style>
